import { useState, useRef } from 'react';
import { Box, Typography, Stack, Button, Dialog, DialogTitle, DialogContent, IconButton, useMediaQuery, Snackbar, Alert } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import imageCompression from 'browser-image-compression';


const DialogUploader = ({ user, openDialogProp }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [openDialog, setOpenDialog] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [imageFileName, setImageFileName] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [profileImages, setProfileImages] = useState([]);
    const [failedImageSize, setFailedImageSize] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [successImageUpload, setSuccessImageUpload] = useState(false);
    const fileInputRef = useRef(null);

    const fileStoreServiceAPIURl = process.env.REACT_APP_FILE_STORAGE_SERVICE_BASE_URL;
    const fileStoreServiceAPIAuthKey = process.env.REACT_APP_FILE_STORAGE_AUTH_KEY;
    const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
    const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;

    const handleDialogClose = () => {
        setOpenDialog(false);
        openDialogProp(false);
        setSelectedFile(null);
        setProfileImages([]);
        setImageFileName(null);
    };

    const anyImageFormatToWebp = async (file) => {
        const options = {
            useWebP: true,
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            initialQuality: 0.5,
        };
        const compressedFile = await imageCompression(file, options);
        return compressedFile;
    };

    const handleFileChangeDialogUploader = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileSize = file.size;
            const fileName = file.name;

            const isValidFileSize = fileSize <= 5 * 1024 * 1024;

            if (!isValidFileSize) {
                setFailedImageSize(true);
                setSnackbarOpen(true);
                return;
            }
            const compressedFile = await anyImageFormatToWebp(file);
            setSelectedFile(compressedFile);
            setImageFileName(fileName);
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImages([reader.result]);
            };
            reader.readAsDataURL(compressedFile);
        }
    };

    const base64ToBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    }

    const handleUpload = async () => {
        if (!profileImages.length) return;

        setUploading(true);
        setShowProgress(true);
        const base64String = profileImages[0].split(',')[1];
        const blob = base64ToBlob(base64String, "image/png");

        try {
            const response = await fetch(
                `${fileStoreServiceAPIURl}/saveImage`,
                {
                    method: 'POST',
                    headers: {
                        'authKey': `${fileStoreServiceAPIAuthKey}`,
                    },
                    body: blob,
                }
            );

            if (response.ok) {
                const data = await response.json();
                const imageUrl = data.imageURL;
                await handleUpdateAuth0User(imageUrl);


            } else {
                console.error('Upload failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setTimeout(() => {
                setUploading(false);
                setShowProgress(false);
                setSuccessImageUpload(true);
                setSnackbarOpen(true);
                setOpenDialog(false);
            }, 3000);
        }
    };

    const handleUpdateAuth0User = async (imageURL) => {

        const headers = new Headers();
        headers.append("authKey", apiAuthKey);
        headers.append("Content-Type", "application/json");

        const data = JSON.stringify({
            "userId": user.sub,
            "data": {
                "dataName": "picture",
                "dataValue": imageURL
            }
        });

        const requestOptions = {
            method: "PUT",
            credentials: "include",
            headers: headers,
            body: data,
            redirect: "follow"
        };

        fetch(`${railwayAPIURL}/api/v1/profile/updateauth0user`, requestOptions)
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => console.error(error));

    };

    return (
        <>
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>
                    Enviar foto
                    <IconButton
                        aria-label="close"
                        onClick={handleDialogClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box
                        component="section"
                        sx={{ p: 2, border: '3px dashed grey', position: 'relative' }}
                        onClick={(e) => {
                            if (e.target.tagName !== 'BUTTON') {
                                fileInputRef.current.click();
                            }
                        }}

                    >
                        <label htmlFor="file-upload" style={{ width: '100%', textAlign: 'center', opacity: uploading ? 0.5 : 1 }}>
                            <Stack direction="column" alignItems="center" spacing={2}>
                                <FileUploadOutlinedIcon sx={{ fontSize: 40, color: '#3D3D41' }} />
                                <Typography variant="h5" component="div">
                                    Clique ou toque para enviar
                                </Typography>
                                <Typography variant="subtitle1" component="div" sx={{ color: '#666' }}>
                                    Clique ou toque para navegar através do seu dispositivo
                                </Typography>
                            </Stack>
                        </label>

                        <input
                            ref={fileInputRef}
                            id="dialog-uploader"
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleFileChangeDialogUploader}
                            disabled={uploading}
                        />

                        {imageFileName && (
                            <Box sx={{ mt: 2, textAlign: 'center', opacity: uploading ? 0.5 : 1 }}>
                                <Typography variant="h5">Imagem selecionada:</Typography>
                                <Typography variant="subtitle1">{imageFileName}</Typography>
                            </Box>
                        )}

                        {selectedFile && (
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: isMobile ? 'center' : 'flex-end',
                                mt: 3
                            }}>
                                {showProgress ? (
                                    <LoadingButton
                                        color="secondary"
                                        loading={uploading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        sx={{
                                            width: '150px',
                                            textTransform: 'capitalize',
                                            backgroundColor: '#614ED9'
                                        }}
                                    >
                                        Enviar
                                    </LoadingButton>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleUpload}
                                        disabled={uploading}
                                        sx={{
                                            width: '150px',
                                            textTransform: 'capitalize',
                                            backgroundColor: '#614ED9'
                                        }}
                                    >
                                        Enviar
                                    </Button>
                                )}
                            </Box>

                        )}
                    </Box>
                </DialogContent>
            </Dialog>
            {successImageUpload && (
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Foto de perfil atualizada com sucesso!
                    </Alert>
                </Snackbar>
            )}
            {failedImageSize && (
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{
                        vertical: isMobile ? 'bottom' : 'bottom',
                        horizontal: 'center'
                    }}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Tamanho da imagem de perfil dever ser menor que 5MB
                    </Alert>
                </Snackbar>
            )}
        </>
    );
}

export default DialogUploader;