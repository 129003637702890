import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton, Alert, Link, useMediaQuery } from '@mui/material';
import Search from './utils/Search';
import WarningBox from './utils/WarningBox';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import { useAuth0 } from '@auth0/auth0-react';

const HeroSection = ({
  isEmailVerified,
  isProfileCompleted,
  userRole
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const urlParams = new URLSearchParams(window.location.search);
  const hasError = urlParams.has('error');
  const [alertVisible, setAlertVisible] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth0();


  const getRandomDelay = () => {
    const delays = [1000, 1200, 1400];
    return delays[Math.floor(Math.random() * delays.length)];
  };

  setTimeout(() => {
    setIsLoadingPage(false);
  }, getRandomDelay());

  const handleLinkClick = () => {
    if (isAuthenticated) {
      navigate('/auth/profile');
    }
  };

  return (
    <Box
      sx={{
        ...(isAuthenticated && userRole === 'contractor' && { padding: isMobile ? '40px' : '80px' }),
        backgroundColor: isAuthenticated && userRole === 'contractor' ? '#F5F5F5' : undefined,
      }}
    >



      {isLoadingPage ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={300} sx={{ borderRadius: 2, mb: 2 }} />
          <Skeleton variant="rectangular" width="100%" height={150} sx={{ borderRadius: 2 }} />
        </>
      ) : !hasError ? (
        <>

          {user && (
            <>
              <Box
                sx={{
                  mt: isAuthenticated && userRole === 'contractor' ? 3 : 10,
                }}
              />
              {!isEmailVerified && isEmailVerified !== undefined && isEmailVerified !== null && (
                <>
                  {alertVisible && (
                    <Alert
                      icon={<MailOutlineIcon />}
                      variant="filled"
                      severity="warning"
                      onClose={() => setAlertVisible(false)}
                      sx={{
                        mb: 1,
                        mt: 2,
                        ml: 2,
                        mr: 2
                      }}
                    >
                      Você ainda não verificou seu e-mail. Por favor, verifique sua caixa de entrada ou spam.
                    </Alert>
                  )}
                </>
              )}

              {!isProfileCompleted && isProfileCompleted !== undefined && isProfileCompleted !== null && (
                <>
                  {alertVisible && (
                    <Alert
                      icon={<AssignmentIndOutlinedIcon />}
                      variant="filled"
                      severity="warning"
                      onClose={() => setAlertVisible(false)}
                      sx={{
                        mb: 1,
                        mt: 2,
                        ml: 2,
                        mr: 2
                      }}
                    >
                      Você ainda não completou seu cadastro. Para usufruir de todos os benefícios do CONEXXA, complete o seu cadastro em <Link
                        component="button"
                        onClick={handleLinkClick}
                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        perfil
                      </Link>.
                    </Alert>
                  )}
                </>
              )
              }
            </>

          )}

          <Box sx={{
            mt: 4
          }}>
            {isAuthenticated && userRole === 'contractor' && (
              <Search />
            )
            }
          </Box>
        </>
      ) : (
        <WarningBox />
      )}
    </Box>
  );
};

export default HeroSection;
