import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UserProfile from './../profile/UserProfile';


export default function UserInfoRegisterDialog({
    open,
    onClose,
    userRole,
    userPicture,
    isProfileCompleted,
}) {
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        onClose();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown={true}
        >
            <DialogTitle>User Information Registration</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please provide your information to complete registration.
                </DialogContentText>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >
                    <UserProfile
                        userRole={userRole}
                        userPicture={userPicture}
                        isProfileCompleted={isProfileCompleted}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}