import React, { useState, useCallback, useEffect } from 'react';
import { Box, CircularProgress, Typography } from "@mui/material";
import { Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserProfile from './components/profile/UserProfile';
import { useAuth0 } from '@auth0/auth0-react';
import HeroFooter from './components/HeroFooter';
import CardsList from './components/CardsList';
import CardDetails from './components/widget/CardDetails';
import Favorites from './components/profile/Favorites';
import Comments from './components/profile/Comments';
import Help from './components/profile/Help';
import SearchResults from './components/SearchResults';
import Booking from './components/book/Booking';
import WarningLogin from './components/utils/WarningLogin';
import BookingScheduled from './components/profile/BookingScheduled';
import AnaliticsService from './analiticsService';
import UserInfoRegisterDialog from "./components/profile/UserInfoRegisterDialog";

function App() {
  const [userRole, setUserRole] = useState();
  const [isEmailVerified, setIsEmailVerified] = useState();
  const [isProfileCompleted, setIsProfileCompleted] = useState();
  const [userPicture, setUserPicture] = useState();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const actionArtistList = 'top5';
  const [isLoading, setIsLoading] = useState(true);
  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);

  const handleUserData = useCallback((userRole, isEmailVerified, isProfileCompleted, userPicture) => {
    setUserRole(userRole);
    setIsEmailVerified(isEmailVerified);
    setIsProfileCompleted(isProfileCompleted);
    setUserPicture(userPicture);
  }, []);


  setTimeout(() => {
    setIsLoading(false);
  }, 1500);

  AnaliticsService.initFirebaseService();

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((claims) => {
        if (claims.is_first_login) {
          console.log("This is the user's first login.");
        } else {
          console.log("This is not the user's first login.");
          setIsRegisterDialogOpen(true);
        }
      });
    }
  }, [isAuthenticated, getIdTokenClaims]);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 30 }}>
        <CircularProgress sx={{ color: "#4F46E5" }} />
        <Typography sx={{ mt: 2 }} variant="h6" color="textSecondary">
          Carregando ...
        </Typography>
      </Box>
    );
  }

  return (
    <Router>
      <div className="App">

      <UserInfoRegisterDialog
        open={isRegisterDialogOpen}
        onClose={() => setIsRegisterDialogOpen(false)}
        userRole={userRole}
        userPicture={userPicture}
        isProfileCompleted={isProfileCompleted}
      />

        <Routes>
          <Route
            path='/'
            element={
              isAuthenticated ? (
                <>
                  <Box sx={{ minHeight: 'calc(100vh - /* height of navbar and footer */)', display: 'flex', flexDirection: 'column' }}>
                    <Navbar
                      handleUserData={handleUserData}
                    />
                    <HeroSection
                      isEmailVerified={isEmailVerified}
                      isProfileCompleted={isProfileCompleted}
                      userRole={userRole}
                    />
                    <CardsList
                      actionArtistList={actionArtistList}
                    />
                    <HeroFooter />
                  </Box>
                </>
              ) : (
                <>
                  <Box sx={{ minHeight: 'calc(100vh - /* height of navbar and footer */)', display: 'flex', flexDirection: 'column' }}>
                    <Navbar
                      handleUserData={handleUserData}
                    />
                    <HeroSection
                    />
                    <Box sx={{ flex: 1 }}>
                      <CardsList
                        actionArtistList={actionArtistList}
                      />
                    </Box>
                    <HeroFooter />
                  </Box>
                </>
              )
            }
          />
          <Route
            path='auth/profile'
            element={
              isAuthenticated ? (
                <>
                  <Navbar
                    handleUserData={handleUserData}
                  />
                  <UserProfile
                    userRole={userRole}
                    userPicture={userPicture}
                    isProfileCompleted={isProfileCompleted}
                  />
                </>
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path='card/details/:id'
            element={
              isAuthenticated ? (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <CardDetails
                    userRole={userRole}
                    userPicture={userPicture}
                  />

                </>
              ) : (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <CardDetails
                  />
                </>
              )
            }
          />

          <Route
            path='card/details/:id/:artist_profile_selected/:artist_country_code/:artist_province_code/:artist_city/:artist_zone/:artist_music_style/:artist_period/:artist_date'
            element={
              isAuthenticated ? (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <CardDetails
                    userRole={userRole}
                    userPicture={userPicture}
                  />

                </>
              ) : (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <CardDetails
                  />
                </>
              )
            }
          />

          <Route
            path='auth/profile/bookingsheduled/:id'
            element={
              isAuthenticated && (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <BookingScheduled
                    userPicture={userPicture}
                    userRole={userRole}
                  />
                </>
              )
            }
          />

          <Route
            path='auth/profile/favorites/:id'
            element={
              isAuthenticated && (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <Favorites
                    userRole={userRole}
                  />
                </>
              )
            }
          />

          <Route
            path='auth/profile/comments/:id'
            element={
              isAuthenticated && (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <Comments />
                </>
              )
            }
          />

          <Route
            path='auth/profile/help/:id'
            element={
              isAuthenticated && (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <Help />
                </>
              )
            }
          />

          <Route
            path="/searchresults"
            element={
              <>
                <Navbar handleUserData={handleUserData} />
                <SearchResults />
              </>

            }
          />

          <Route
            path='booking/:id'
            element={
              isAuthenticated ? (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <Booking />
                </>
              ) : (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <WarningLogin
                    title="Ops! Você não tem acesso a isso"
                    description="Você precisa estar logado ou criar uma conta contratante para fazer sua reserva. Para isso Inicie Sua Sessão no canto superior direito."
                    highlightedText="Inicie Sua Sessão"
                  />

                </>
              )
            }
          />

          <Route
            path='access_refused'
            element={
              isAuthenticated && (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <WarningLogin
                    title="Ops! Você não tem acesso a isso"
                    description="Você precisa ter uma conta do tipo Contratante para fazer uma reserva."
                    highlightedText="Contratante"
                  />
                </>
              )
            }
          />

        </Routes>



      </div>
    </Router>

  );
}

export default App;
