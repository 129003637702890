import { useMediaQuery, Box, TextField, Typography, Button, CircularProgress } from "@mui/material";
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const SendMessage = () => {
    const { user } = useAuth0();
    const [messageHelp, setMessageHelp] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isMessageSent, setIsMessageSent] = useState(false);
    const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
    const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;
    const conexxaEmail = process.env.REACT_APP_CONEXXA_EMAIL;

    const handleSendMessageHelp = (event) => {
        setMessageHelp(event.target.value);
    };

    const generateTicketNumber = () => Math.floor(10000000000 + Math.random() * 90000000000);

    const handleSendHelpMessage = async () => {
        setIsLoading(true);
        const ticketNumber = generateTicketNumber();
        try {
            const data = JSON.stringify({
                "to_address": conexxaEmail,
                "subject": `Ajuda - ticket No. ${ticketNumber}`,
                html: `<p>Email: ${user.email}</p><p>Usuário: ${user.name}</p><p></p><p>${messageHelp}</p>`
            });

            const response = await fetch(`${railwayAPIURL}/api/v1/profile/sendemail`, {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                    'authKey': apiAuthKey,
                },
                body: data,
            });

            if (response.ok) {
                console.log("Message sent successfully!");
                setIsMessageSent(true);
            }

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(`Failed to send message: ${errorMessage}`);
            }

            console.log("Message sent successfully!");
        } catch (error) {
            console.error('Error sending message:', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
            maxHeight: isMobile ? '380px' : '350px',
            mt: 2,
            mx: isMobile ? 2 : 10,
            width: '100%',
            boxSizing: 'border-box'
        }}>
            <img
                src="/static/images/help_menu.webp"
                alt=""
                style={{
                    width: isMobile ? '100%' : '256px',
                    height: 'auto',
                    maxWidth: '256px',
                    marginTop: '2px'
                }}
            />
            <Box sx={{
                width: '100%',
                ml: isMobile ? 0 : 2,
                mr: isMobile ? 0 : 2,
                px: isMobile ? 0 : 1,
                boxSizing: 'border-box'
            }}>

                <Box sx={{ width: '100%', maxWidth: '500px', mx: 'auto' }}>
                    {isMessageSent ? (
                            <Typography
                                variant="h5"
                                sx={{ 
                                    mt: 2, 
                                    color: '#000', 
                                    fontWeight: 'normal' 
                                }}
                            >
                                Obrigado por enviar sua dúvida, iremos analisar e tentar resolvê-la o mais rápido possível.
                            </Typography>
                    ) : (
                        <>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                sx={{
                                    textAlign: 'left',
                                    color: '#2e263db3',
                                    mb: 2,
                                    fontSize: isMobile ? '14px' : '16px',
                                    width: '100%',
                                    maxWidth: '500px',
                                    mx: 'auto',
                                    px: isMobile ? 0 : 1,
                                }}
                            >
                                Descreva seu problema abaixo:
                            </Typography>
                            <TextField
                                required
                                id="send-messsage-help"
                                label="Descrição"
                                multiline
                                rows={4}
                                value={messageHelp}
                                onChange={handleSendMessageHelp}
                                sx={{
                                    width: '100%',
                                    '& .MuiOutlinedInput-root': { fontSize: '1rem' }
                                }}
                                InputProps={{ sx: { fontSize: isMobile ? '14px' : '16px' } }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        textTransform: 'capitalize',
                                        backgroundColor: '#614ED9'
                                    }}
                                    onClick={handleSendHelpMessage}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "Enviar"}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>



            </Box>
        </Box>
    );
};

export default SendMessage;
