import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MenuIcon from '@mui/icons-material/Menu';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import HelpIcon from '@mui/icons-material/Help';
import {
  AppBar,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Toolbar,
  Typography,
  useMediaQuery,
  Stack
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import BoxProfile from './profile/BoxProfile';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import AnaliticsService from '../analiticsService';

const Navbar = ({ handleUserData }) => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuProfileAnchorEl, setMenuProfileAnchorEl] = useState(null);
  const [menuNotificationAnchorEl, setMenuNotificationAnchorEl] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { loginWithRedirect, logout, isAuthenticated, user, getAccessTokenSilently, isLoading, getIdTokenClaims } = useAuth0();
  const [isMenuItem, setIsMenuItem] = useState();
  const [hasUserNotifications, setHasUserNotifications] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);
  const [expandedNotification, setExpandedNotification] = useState(null);
  const [readNotifications, setReadNotifications] = useState(new Set());
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const [userRole, setUserRole] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(null);
  const [isProfileCompleted, setIsProfileCompleted] = useState(null);
  const [userPicture, setUserPicture] = useState();
  const nameSpace = process.env.REACT_APP_CUSTOM_CLAIM_NAMESPACE;

  const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
  const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;

  const getRandomDelay = () => {
    const delays = [1000, 1200, 1400];
    return delays[Math.floor(Math.random() * delays.length)];
  };

  useEffect(() => {
    const setMenu = async () => {
      setIsMenuItem(true);
    };

    setMenu();

  }, [setIsMenuItem]);

  setTimeout(() => {
    setIsLoadingPage(false);
  }, getRandomDelay());

  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        if (user && user.sub) {
          const response = await fetch(
            `${railwayAPIURL}/api/v1/profile/getauth0userroles?auth0_sub_id=${user.sub}`,
            {
              method: 'GET',
              credentials: "include",
              headers: {
                'Content-Type': 'application/json',
                'authKey': apiAuthKey,
              },
            }
          );
          if (!response.ok) {
            throw new Error('Falha ao recuperar roles');
          }
          const result = await response.json();
          setUserRole(result.role[0].name);
        }
      } catch (error) {
        console.error('Erro ao recuperar roles:', error);
      }
    };

    if (isAuthenticated) {
      fetchUserRoles();
    }
  }, [railwayAPIURL, apiAuthKey, user, userRole, isAuthenticated]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (user && user.sub) {
          const response = await fetch(
            `${railwayAPIURL}/api/v1/profile/getauth0user?auth0_sub_id=${user.sub}`,
            {
              method: 'GET',
              credentials: "include",
              headers: {
                'Content-Type': 'application/json',
                'authKey': apiAuthKey,
              },
            }
          );
          if (!response.ok) {
            throw new Error('Falha ao recuperar usuário');
          }
          const result = await response.json();
          setIsEmailVerified(result.response.email_verified);
          setIsProfileCompleted(result.response.user_metadata.is_profile_completed);
          setUserPicture(result.response.picture);

          handleUserData(userRole, isEmailVerified, isProfileCompleted, userPicture);
        }
      } catch (error) {
        console.error('Erro ao recuperar roles:', error);
      }
    };

    if (isAuthenticated) {
      fetchUser();
    }
  }, [getIdTokenClaims, apiAuthKey, railwayAPIURL, isAuthenticated, user, userRole, isLoading, nameSpace, isEmailVerified, isProfileCompleted, userPicture, handleUserData]);

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (isAuthenticated) {
        try {
          await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          });
        } catch (error) {
          console.error('Error fetching access token:', error.message);
          if (error.error === 'consent_required') {
            loginWithRedirect();
          }
        }
      }
    };

    fetchAccessToken();

  }, [getAccessTokenSilently, isAuthenticated, loginWithRedirect]);


  useEffect(() => {
    const fetchUserNotifications = async () => {
      try {
        const response = await fetch(
          `${railwayAPIURL}/api/v1/profile/getnotifications?auth0_sub_id=${user.sub}`,
          {
            method: 'GET',
            credentials: "include",
            headers: {
              'Content-Type': 'application/json',
              'authKey': apiAuthKey,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const notificationsArray = data.notifications;

          setNotificationsList(notificationsArray);

          const hasUnreadNotifications = data.notifications.some(notification => !notification.is_read);

          setHasUserNotifications(hasUnreadNotifications);
        } else {
          console.error('Falha ao recuperar notificações:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao recuperar notificações:', error);
      }
    };

    if (apiAuthKey && railwayAPIURL && isAuthenticated) {
      fetchUserNotifications();
    }
  }, [isAuthenticated, user, apiAuthKey, railwayAPIURL]);

  const handleMenuProfileClick = (event) => {
    setMenuProfileAnchorEl(event.currentTarget);
    if (!isAuthenticated) {
      AnaliticsService.sendEvent(AnaliticsService.EventTypes.DidClickSignUp);
    }
  };

  const handleCloseMenuProfile = () => {
    setMenuProfileAnchorEl(null);
  };

  const handleMenuNotificationClick = async (event) => {
    setMenuNotificationAnchorEl(event.currentTarget);
  };

  const handleCloseMenuNotification = () => {
    setMenuNotificationAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };


  const handleMenuItemClick = (menuItem) => {
    if (isAuthenticated) {
      handleUserData(userRole, isEmailVerified, isProfileCompleted, userPicture);

      if (menuItem === 'contractor_profile' || menuItem === 'artist_profile') {
        navigate('/auth/profile');
      }

      handleCloseMenuProfile();
      // setIsLandingPage(false);
      if (isMobile) {
        toggleDrawer(false)();
      }
    }

  };

  const handleNotificationClick = (notificationId) => {
    if (expandedNotification === notificationId) {
      setExpandedNotification(null);
    } else {
      setExpandedNotification(notificationId);
    }
  };

  const truncateText = (text, limit = 30) => {
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  };

  const handleSetNotificationAsRead = async (auth0_sub_id, notification_id) => {
    try {
      await fetch(
        `${railwayAPIURL}/api/v1/profile/updatenotification`,
        {
          method: 'PUT',
          credentials: "include",
          headers: {
            'Content-Type': 'application/json',
            'authKey': apiAuthKey,
          },
          body: JSON.stringify({
            auth0_sub_id: auth0_sub_id,
            notification_id: notification_id,
          }),
        }
      );

    } catch (error) {
      console.error('Error ao atualizar notificações:', error);
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {isLoadingPage ? (
            <>
              <Skeleton variant="rounded" sx={{ width: '100%', height: 30 }} />
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => window.location.href = '/'}>
                <Box component="img" src="/cnxx.png" alt="Conexxa Logo" sx={{ height: 40, marginRight: 0 }} />
                <Typography variant="h6">CONEXXA</Typography>
              </Box>

              {isMobile ? (
                <>
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                    sx={{ display: { xs: 'block', sm: 'none' } }}
                  >
                    <MenuIcon />
                  </IconButton>
                </>
              ) : (
                <>

                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <>
                      <Stack
                        direction={'row'}
                      >
                        {isAuthenticated && (
                          <IconButton
                            color="inherit"
                            aria-label="Notificações"
                            onClick={handleMenuNotificationClick}
                          >
                            <Box sx={{ position: 'relative', display: 'inline-block' }}>
                              <NotificationsActiveOutlinedIcon />
                              {isAuthenticated && user && (
                                <>
                                  {hasUserNotifications && (
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        width: 10,
                                        height: 10,
                                        backgroundColor: 'white',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        top: -3,
                                        right: -1,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: 6,
                                          height: 6,
                                          backgroundColor: '#FF4C51',
                                          borderRadius: '50%',
                                        }}
                                      />
                                    </Box>
                                  )}

                                </>
                              )}
                            </Box>
                          </IconButton>
                        )}


                        <Chip
                          icon={
                            isAuthenticated && user ? (
                              <Box sx={{ position: 'relative', display: 'inline-block' }}>
                                <Avatar src={userPicture} sx={{ width: 32, height: 32 }} />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    width: 10,
                                    height: 10,
                                    backgroundColor: 'white',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    top: 20,
                                    right: -1,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: 6,
                                      height: 6,
                                      backgroundColor: '#56ca00',
                                      borderRadius: '50%',
                                    }}
                                  />
                                </Box>
                              </Box>
                            ) : (
                              <>
                                <AccountCircleIcon />
                              </>


                            )
                          }
                          label={isAuthenticated ? user.nickname : "Iniciar sessão"}
                          variant="outlined"
                          color="primary"
                          onClick={handleMenuProfileClick}
                          clickable
                          sx={{
                            fontWeight: 'bold',
                            color: '#614ed9',
                            height: 40,
                            px: 2,
                            '& .MuiChip-icon': {
                              marginRight: 1,
                            },
                          }}
                        />
                      </Stack>

                    </>

                  )}
                </>
              )}
            </>
          )}


        </Toolbar>



      </AppBar>



      <Menu
        anchorEl={menuProfileAnchorEl}
        open={Boolean(menuProfileAnchorEl)}
        onClose={handleCloseMenuProfile}
        PaperProps={{
          sx: {
            width: '300px',
            '& .MuiMenuItem-root': {
              fontSize: '0.875rem',
            },
            '& .MuiPaper-root': {
              borderRadius: 1,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              marginTop: '8px',
            },
            ...(isMobile ? {} : {
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                borderWidth: '8px',
                borderStyle: 'solid',
                borderColor: 'transparent transparent #ffffff transparent',
                borderBottomColor: 'rgba(0, 0, 0, 0.1)',
                width: 0,
                height: 0,
              },
            }),
          },
        }}
      >
        {isAuthenticated ? [
          userRole === 'contractor' ? [
            <MenuItem key="contractor_profile" onClick={() =>
              handleMenuItemClick('contractor_profile')
            }>
              <BoxProfile
                isAuthenticated={isAuthenticated}
                user={user}
                userRole={userRole}
                isMenuItem={isMenuItem}
                userPicture={userPicture}
              />
            </MenuItem>

          ] : [
            userRole === 'artist' && [
              <MenuItem key="artist_profile" onClick={() =>
                handleMenuItemClick('artist_profile')
              }>
                <BoxProfile
                  isAuthenticated={isAuthenticated}
                  user={user}
                  userRole={userRole}
                  isMenuItem={isMenuItem}
                  userPicture={userPicture}
                />
              </MenuItem>

            ]
          ]
        ] : [
          <MenuItem key="unauthenticated_profile">
            <BoxProfile
              isAuthenticated={isAuthenticated}
              user={user}
              userRole={userRole}
              isMenuItem={isMenuItem}
              userPicture={userPicture}
            />
          </MenuItem>
        ]}


        <Divider />

        {isAuthenticated && user ? [

          userRole === 'contractor' ? [

            <MenuItem
              key="events"
              onClick={() => navigate(`/auth/profile/bookingsheduled/${user.sub}`)}
            >
              <ListItemIcon>
                <EventAvailableIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Agenda" />
            </MenuItem>,

            <MenuItem
              key="favorites"
              onClick={() => navigate(`/auth/profile/favorites/${user.sub}`)}
            >
              <ListItemIcon>
                <FavoriteBorderIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Favoritos" />
            </MenuItem>,

            <MenuItem
              key="comments_contractor"
              onClick={() => navigate(`/auth/profile/comments/${user.sub}`)}
            >
              <ListItemIcon>
                <SmsOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Meus comentários" />
            </MenuItem>,

            <MenuItem
              key="help_contractor"
              onClick={() => navigate(`/auth/profile/help/${user.sub}`)}
            >
              <ListItemIcon>
                <HelpIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Ajuda" />
            </MenuItem>,

            <MenuItem
              key="logout"
              onClick={() => {
                logout({
                  returnTo: window.location.origin,
                });
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 'bold',
                    color: '#614ed9',
                  },
                }}
                primary="Sair"
              />
            </MenuItem>

          ] : [
            userRole === 'artist' && [

              <MenuItem
                key="events"
                onClick={() => navigate(`/auth/profile/bookingsheduled/${user.sub}`)}
              >
                <ListItemIcon>
                  <EventAvailableIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Agenda" />
              </MenuItem>,

              <MenuItem
                key="comments_artist"
                onClick={() => navigate(`/auth/profile/comments/${user.sub}`)}
              >
                <ListItemIcon>
                  <SmsOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Meus comentários" />
              </MenuItem>,

              <MenuItem
                key="help_artist"
                onClick={() => navigate(`/auth/profile/help/${user.sub}`)}
              >
                <ListItemIcon>
                  <HelpIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Ajuda" />
              </MenuItem>,

              <MenuItem
                key="logout"
                onClick={() => {
                  logout({
                    returnTo: window.location.origin,
                  });
                }}
              >
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      fontWeight: 'bold',
                      color: '#614ed9',
                    },
                  }}
                  primary="Sair"
                />
              </MenuItem>

            ]
          ]
        ] : [

          <MenuItem key="login-role" disabled>
            <Typography variant="body2" sx={{ padding: 1 }}>
              Iniciar sessão como:
            </Typography>
          </MenuItem>,

          <MenuItem key="artist" onClick={() => {
            loginWithRedirect({
              authorizationParams: {
                role: 'artist',
              },
            })
            AnaliticsService.sendEvent(AnaliticsService.EventTypes.DidClickBandRegister);
          }
          }>
            <MusicNoteIcon sx={{
              mr: 1,
              color: '#2e263de6'
            }} />
            Banda/Músico/DJ
          </MenuItem>,

          <MenuItem key="contractor" onClick={() => {
            loginWithRedirect({
              authorizationParams: {
                role: 'contractor',
              },
            })
            AnaliticsService.sendEvent(AnaliticsService.EventTypes.DidClickContractorRegister);
          }
          }>
            <BusinessIcon sx={{
              mr: 1,
              color: '#2e263de6'
            }} />
            Contratante
          </MenuItem>

        ]}
      </Menu >

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Box
          sx={{ width: 250, height: '100%', display: 'flex', flexDirection: 'column' }}
          role="presentation"
        >

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', p: 2 }}>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <Stack
                  direction={'row'}
                >
                  {isAuthenticated && (
                    <IconButton
                      color="inherit"
                      aria-label="Notificações"
                      onClick={handleMenuNotificationClick}
                    >
                      <Box sx={{ position: 'relative', display: 'inline-block' }}>
                        <NotificationsActiveOutlinedIcon />
                        {isAuthenticated && user && (
                          <>
                            {hasUserNotifications && (
                              <Box
                                sx={{
                                  position: 'absolute',
                                  width: 10,
                                  height: 10,
                                  backgroundColor: 'white',
                                  borderRadius: '50%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  top: -3,
                                  right: -1,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: 6,
                                    height: 6,
                                    backgroundColor: '#FF4C51',
                                    borderRadius: '50%',
                                  }}
                                />
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    </IconButton>
                  )}
                  <Chip
                    icon={
                      isAuthenticated && user ? (
                        <Box sx={{ position: 'relative', display: 'inline-block' }}>
                          <Avatar src={userPicture} sx={{ width: 32, height: 32 }} />
                          <Box
                            sx={{
                              position: 'absolute',
                              width: 10,
                              height: 10,
                              backgroundColor: 'white',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              top: 20,
                              right: -1,
                            }}
                          >
                            <Box
                              sx={{
                                width: 6,
                                height: 6,
                                backgroundColor: '#56ca00',
                                borderRadius: '50%',
                              }}
                            />
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <AccountCircleIcon />
                        </>


                      )
                    }
                    label={isAuthenticated ? user.nickname : "Iniciar sessão"}
                    variant="outlined"
                    color="primary"
                    onClick={handleMenuProfileClick}
                    clickable
                    sx={{
                      fontWeight: 'bold',
                      color: '#614ed9',
                      height: 40,
                      px: 2,
                      '& .MuiChip-icon': {
                        marginRight: 1,
                      },
                    }}
                  />
                </Stack>
              </>

            )}
          </Box>
        </Box>
      </Drawer>

      <Menu
        anchorEl={menuNotificationAnchorEl}
        open={Boolean(menuNotificationAnchorEl)}
        onClose={handleCloseMenuNotification}
        PaperProps={{
          sx: {
            width: '300px',
            marginLeft: isMobile ? '0px' : '-45px',
            maxHeight: '400px',
            overflowY: 'auto',
            '& .MuiMenuItem-root': {
              fontSize: '0.875rem',
            },
            '& .MuiPaper-root': {
              borderRadius: 1,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              marginTop: '8px',
            },
            ...(isMobile ? {} : {
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                borderWidth: '8px',
                borderStyle: 'solid',
                borderColor: 'transparent transparent #ffffff transparent',
                borderBottomColor: 'rgba(0, 0, 0, 0.1)',
                width: 0,
                height: 0,
              },
            }),
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            ml: 2,
          }}
        ></Box>

        <MenuItem>
          <Typography variant="subtitle1" gutterBottom>
            Notificações
          </Typography>
        </MenuItem>

        <Divider />

        {notificationsList.map((notification, index) => (
          <MenuItem
            key={index}
            sx={{
              padding: 1.5,
              alignItems: 'flex-start',
            }}
            onClick={() => {
              if (!readNotifications.has(notification._id) && !notification.is_read) {
                handleSetNotificationAsRead(notification.auth0_sub_id, notification._id);
                setReadNotifications((prev) => new Set(prev).add(notification._id));
              }
              handleNotificationClick(notification._id);
            }}
          >
            <Stack direction="row" spacing={2} alignItems="flex-start">
              {readNotifications.has(notification._id) || notification.is_read ? (
                <DraftsOutlinedIcon sx={{ color: 'gray' }} />
              ) : (
                <EmailOutlinedIcon sx={{ color: '#614ED9' }} />
              )}
              <Stack direction="column">
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: notification.is_read ? 'normal' : 'bold', cursor: 'pointer' }}
                >
                  {notification.notification_title}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{
                    color: 'gray',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    maxWidth: '230px',
                    display: '-webkit-box',
                    WebkitLineClamp: expandedNotification === notification._id ? 'initial' : 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                >
                  {expandedNotification === notification._id
                    ? notification.notification_text
                    : truncateText(notification.notification_text)}
                </Typography>
              </Stack>
            </Stack>
          </MenuItem>

        ))}
      </Menu>

    </>
  );
};

export default Navbar;
