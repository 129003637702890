import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, useMediaQuery } from "@mui/material";
import SearchResultCard from "../widget/SearchResultCard";
import HeroFooter from "../HeroFooter";

const Favorites = ({ userRole }) => {
    const { id: userID } = useParams();
    const [artists, setArtists] = useState([]);
    const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
    const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchArtistListFavorites = async () => {
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/profile/getuserfavorites?auth0_sub_id=${userID}`,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json",
                            authKey: apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch user artist list");
                }
                const result = await response.json();
                setArtists(result);
            } catch (error) {
                console.error("Error fetching user artist list:", error);
            }
        };

        if (userID) {
            fetchArtistListFavorites();
        }
    }, [userID, apiAuthKey, railwayAPIURL]);

    return (
        <>
            {userID && artists.length > 0 && (
                <>

                    <Box
                        sx={{
                            marginTop: 10,
                            marginBottom: 2,
                            marginLeft: { xs: 2, sm: 4 },
                            marginRight: { xs: 2, sm: 4 },
                        }}
                    >
                        <Typography
                            variant={isMobile ? "h4" : "h4"}
                            fontWeight="bold"
                            sx={{
                                textAlign: isMobile ? 'center' : 'left',
                                color: '#2e263de6'
                            }}
                        >
                            Favoritos
                        </Typography>
                        <Typography
                            variant={"subtitle1"}
                            sx={{
                                textAlign: isMobile ? 'center' : 'left',
                                color: '#2e263db3',
                                mb: 4
                            }}
                        >
                            Os seus artistas favoritos ficam salvos aqui
                        </Typography>
                        {artists.map((artist) => (
                            <SearchResultCard
                                key={artist.id}
                                artist={artist}
                            />
                        ))}
                    </Box>
                </>
            )}

            <HeroFooter />
        </>
    );
};

export default Favorites;