import React from 'react';
import {
    Typography,
    Button,
    Box,
    Container,
    Paper,
    useMediaQuery,
    useTheme,
    Alert
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const PostBooking = ({ whatsappLink }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ p: 4, mt: 12, textAlign: 'center' }}>
                <Box sx={{ mb: 4 }}>
                    <img
                        src="/static/images/congratulation.png"
                        alt="Congratulations"
                        style={{ maxWidth: isMobile ? '100px' : '150px', width: '100%' }}
                    />
                </Box>
                <Typography variant="h4" gutterBottom sx={{ color: '#614ed9', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CheckCircleOutlineIcon sx={{ mr: 1, fontSize: 40 }} />
                    Parabéns, sua reserva foi confirmada!
                </Typography>
                <Typography variant="h6" sx={{ my: 3 }}>
                    Agora você pode combinar os detalhes com o contratante no botão abaixo.
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<WhatsAppIcon />}
                    href={whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="large"
                    sx={{
                        backgroundColor: '#25D366',
                        '&:hover': { backgroundColor: '#128C7E' },
                        mb: 4,
                        py: 1.5,
                        px: 4
                    }}
                >
                    Abrir WhatsApp
                </Button>
                <Alert severity="info">
                    Lembre-se de que, em caso de 
                    {" "} <span style={{ color: '#614ed9', textDecoration: 'underline' }}>cancelamento</span> {" "} 
                    por qualquer uma das partes, é recomendado realizar o cancelamento o mais rápido possível em
                    {" "} <span style={{ color: '#614ed9' }}>"Agenda"</span> {" "}
                    para deixar a sua agenda disponível novamente para outros contratantes.
                </Alert>
            </Paper>
        </Container>
    );
};

export default PostBooking;