import { CircularProgress, useMediaQuery, Box, Grid, Typography, Card, CardMedia, CardContent, Chip, IconButton, Button } from "@mui/material";
import { useState, useEffect } from 'react';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import StarIcon from '@mui/icons-material/Star';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import ThereIsNothingHere from "./utils/ThereIsNothingHere";

const CardsList = ({
    actionArtistList,
    userID,
    userRole
}) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
    const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;
    const [artists, setArtists] = useState([]);
    const isTop5 = actionArtistList === 'top5' ? true : false;
    const title = "Ops!";
    const subtitle = isTop5
        ? "Ainda não tem nada por aqui. Tente novamente mais tarde."
        : "Ainda não tem artistas favoritos? Explore nossa seleção e encontre o artista perfeito para sua próxima experiência!";
    const [loading, setLoading] = useState(true);

    const [scrollPosition, setScrollPosition] = useState(0);
    const cardWidth = 320;
    const cardGap = 16;

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchTop5UserArtistList = async () => {
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/profile/getuserlist?top5=${isTop5}`,
                    {
                        method: 'GET',
                        credentials: "include",
                        headers: {
                            'Content-Type': 'application/json',
                            'authKey': apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch user artist list');
                }
                const result = await response.json();
                const filteredArtists = result.artists.filter(artist =>
                    artist.music_styles.length > 0 &&
                    artist.period.length > 0 &&
                    artist.artist_pictures.length > 0
                );

                setArtists(filteredArtists);
            } catch (error) {
                console.error('Error fetching user artist list:', error);
            }
        };

        const fetchArtistListFavorites = async () => {
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/profile/getuserfavorites?auth0_sub_id=${userID}`,
                    {
                        method: 'GET',
                        credentials: "include",
                        headers: {
                            'Content-Type': 'application/json',
                            'authKey': apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch user artist list');
                }
                const result = await response.json();
                setArtists(result);
            } catch (error) {
                console.error('Error fetching user artist list:', error);
            }
        };
        if (railwayAPIURL && apiAuthKey && actionArtistList === 'top5') {
            setLoading(true);
            fetchTop5UserArtistList().finally(() => setLoading(false));
        }
        if (railwayAPIURL && apiAuthKey && actionArtistList === 'favorites') {
            setLoading(true);
            fetchArtistListFavorites().finally(() => setLoading(false));
        }
    }, [railwayAPIURL, apiAuthKey, actionArtistList, userID, isTop5]);

    const calculateWidth = (baseChValue) => {
        const chInPixels = 8;
        const baseWidthInPixels = baseChValue * chInPixels;
        const adjustedWidthInPixels = baseWidthInPixels - (screenWidth * 0.1);
        const adjustedWidthInCh = adjustedWidthInPixels / chInPixels;
        return `${adjustedWidthInCh}ch`;
    };

    const getChipColor = (artistType) => {
        switch (artistType) {
            case 'band':
                return { bg: '#8C57FF', text: '#C6ACFF' };
            case 'musician':
                return { bg: '#16B1FF', text: '#0087C9' };
            case 'dj':
                return { bg: '#FFB400', text: '#9B6E00' };
            default:
                return { bg: '#CCCCCC', text: '#666666' };
        }
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };

    const UsersCard = ({ artist }) => {
        const [currentImageIndex, setCurrentImageIndex] = useState(0);

        const nextImage = () => {
            setCurrentImageIndex((prevIndex) =>
                (prevIndex + 1) % artist.artist_pictures.length
            );
        };

        const prevImage = () => {
            setCurrentImageIndex((prevIndex) =>
                (prevIndex - 1 + artist.artist_pictures.length) % artist.artist_pictures.length
            );
        };

        return (
            <Card
                sx={{
                    width: isMobile ? '100%' : '320px',
                    maxWidth: '100%',
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'auto',
                }}
            >
                <Box sx={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                        sx={{
                            mt: 1,
                            borderRadius: '5px',
                            overflow: 'hidden',
                            width: '95%',
                            maxWidth: 300,
                        }}
                    >
                        <CardMedia
                            sx={{
                                width: '100%',
                                height: 160,
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            image={artist.artist_pictures[currentImageIndex]}
                        />
                    </Box>

                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '-20px',
                            transform: 'translateY(-50%)',
                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            },
                            '&:active': {
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            },
                            transition: 'background-color 0.3s',
                            height: '50px',
                            width: '40px',
                            display: 'flex',
                            alignItems: 'right',
                            justifyContent: 'right',
                            borderRadius: '5px',
                        }}
                    >
                        <IconButton
                            onClick={prevImage}
                            sx={{
                                padding: 0,
                                '&:hover': { backgroundColor: 'transparent' },
                            }}
                        >
                            <ArrowBackIosNewIcon sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
                        </IconButton>
                    </Box>

                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '-20px',
                            transform: 'translateY(-50%)',
                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            },
                            '&:active': {
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            },
                            transition: 'background-color 0.3s',
                            height: '50px',
                            width: '40px',
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'left',
                            borderRadius: '5px',
                        }}
                    >
                        <IconButton
                            onClick={nextImage}
                            sx={{
                                padding: 0,
                                '&:hover': { backgroundColor: 'transparent' },
                            }}
                        >
                            <ArrowForwardIosIcon sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
                        </IconButton>
                    </Box>
                </Box>
                <CardContent >
                    <Box
                        onClick={() => navigate(`/card/details/${artist.auth0_sub_id}`)}
                        sx={{ cursor: 'pointer' }}
                    >

                        <Grid container spacing={20}>
                            <Grid item xs={6}>
                                <Chip
                                    label={
                                        artist.artist_type === 'band' ? 'banda' :
                                            artist.artist_type === 'musician' ? 'músico' :
                                                artist.artist_type === 'dj' ? 'dj' :
                                                    artist.artist_type
                                    }
                                    sx={{
                                        backgroundColor: getChipColor(artist.artist_type).bg,
                                        color: getChipColor(artist.artist_type).text,
                                        fontWeight: 'bold',
                                        height: '24px',
                                        fontSize: '0.75rem',
                                        padding: '0 8px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '120px',
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} sx={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#2e263de6',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {artist.rating.toFixed(1)}
                                    <StarIcon
                                        fontSize="medium"
                                        sx={{
                                            color: '#FFB400',
                                            verticalAlign: 'middle',
                                            marginBottom: '5px',
                                            ml: 1
                                        }}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                mt: 1,
                                textAlign: 'left',
                                textJustify: 'inter-word',
                                color: '#2e263de6'
                            }}
                        >
                            {artist.artist_name}
                        </Typography>
                        <Typography
                            variant="body2"
                            gutterBottom
                            sx={{
                                textAlign: 'left',
                                textJustify: 'inter-word',
                                color: '#2e263db3'
                            }}
                        >

                            {truncateText(artist.artist_description, 60)}
                        </Typography>
                        <Grid container alignItems="center">
                            <Grid item xs={8} sx={{ textAlign: 'left' }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#2e263de6',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <LocalOfferOutlinedIcon
                                        fontSize="small"
                                        sx={{
                                            verticalAlign: 'middle',
                                            mr: 1,
                                            color: '#614ED9'
                                        }}
                                    />
                                    R$ {artist.price}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#4F46E5',
                                        '&:hover': {
                                            backgroundColor: '#4338CA',

                                        },
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    Ver mais
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    const handleScroll = (direction) => {
        const scrollAmount = cardWidth + cardGap;

        if (direction === 'left') {
            setScrollPosition((prevPosition) => Math.max(0, prevPosition - scrollAmount));
        } else {
            setScrollPosition((prevPosition) => prevPosition + scrollAmount);
        }
    };
    
    useEffect(() => {
        console.log("isMobile:", isMobile);
        console.log("artists:", artists);
        console.log("Rendering scrollable layout result:", !isMobile && artists.length > 0);
    }, [isMobile, artists]);

    if (loading) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 30 }}>
                <CircularProgress sx={{ color: "#4F46E5" }} />
                <Typography sx={{ mt: 2 }} variant="h6" color="textSecondary">
                    {actionArtistList === 'top5' ? 'Carregando artistas top 5 ...' : actionArtistList === 'favorites' ? 'Carregando favoritos...' : 'Carregando ...'}
                </Typography>
            </Box>
        );
    }

    

    return (
        <>
            {!loading && artists && artists.length > 0 ? (
                <Box sx={{
                    maxHeight: isMobile ? '380px' : '350px',
                    paddingBottom: isMobile ? `${artists.length * 350}px` : `${artists.length * 60}px`,
                    mt: 2,
                    ml: isMobile ? 5 : 10,
                    mr: isMobile ? 5 : 10,
                }}
                >
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': {
                                width: isMobile ? calculateWidth(35) : '55ch',
                                mt: 3,
                            },
                            paddingBottom: '100px',
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Box sx={{
                            padding: isMobile ? '20px 5px' : '20px 10px',
                            marginBottom: '40px',
                        }}>

                            <Box sx={{
                                mt: 6,
                                mb: 3
                            }}>
                                {actionArtistList === 'top5' && (
                                    <>
                                        <Typography
                                            variant={isMobile ? "h4" : "h4"}
                                            fontWeight="bold"
                                            sx={{
                                                textAlign: isMobile ? 'center' : 'left',
                                                color: '#2e263de6'
                                            }}
                                        >
                                            Top 5 artistas
                                        </Typography>

                                        <Typography
                                            variant={"subtitle1"}
                                            sx={{
                                                textAlign: isMobile ? 'center' : 'left',
                                                color: '#2e263db3'
                                            }}
                                        >
                                            Os artistas mais bem ranqueados
                                        </Typography>
                                    </>
                                )}

                                {actionArtistList === 'favorites' && (
                                    <>
                                        <Typography
                                            variant={isMobile ? "h4" : "h4"}
                                            fontWeight="bold"
                                            sx={{
                                                textAlign: isMobile ? 'center' : 'left',
                                                color: '#2e263de6'
                                            }}
                                        >
                                            Favoritos
                                        </Typography>

                                        {userRole === 'contractor' && (
                                            <Typography
                                                variant={"subtitle1"}
                                                sx={{
                                                    textAlign: isMobile ? 'center' : 'left',
                                                    color: '#2e263db3'
                                                }}
                                            >
                                                Os seus artistas favoritos ficam salvos aqui
                                            </Typography>
                                        )}

                                        {/* {userRole === 'artist' && (
                                            <Typography
                                                variant={"subtitle1"}
                                                sx={{
                                                    textAlign: isMobile ? 'center' : 'left',
                                                    color: '#2e263db3'
                                                }}
                                            >
                                                Seus contratantes favoritos
                                            </Typography>
                                        )} */}
                                    </>
                                )}


                            </Box>

                            {isMobile ? (
                                <Grid container spacing={2}>
                                    {artists.map((artist, index) => (
                                        <Grid item xs={12} sm={12} key={index}>
                                            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: 2 }}>
                                                <UsersCard artist={artist} />
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    {scrollPosition > 0 && (
                                        <IconButton
                                            onClick={() => handleScroll('left')}
                                            sx={{
                                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                },
                                            }}
                                        >
                                            <ArrowBackIosNewIcon />
                                        </IconButton>
                                    )}

                                    <Box sx={{
                                        overflowX: 'hidden',
                                        width: '100%',
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            transition: 'transform 0.5s ease-in-out', 
                                            transform: `translateX(-${scrollPosition}px)`,
                                            gap: 2,
                                            paddingBottom: '10px',
                                        }}>
                                            {artists.map((artist, index) => (
                                                <UsersCard artist={artist} key={index} />
                                            ))}
                                        </Box>
                                    </Box>

                                    {
                                        scrollPosition + (cardWidth + cardGap) * 3 < (artists.length * (cardWidth + cardGap)) && (
                                            <IconButton
                                                onClick={() => handleScroll('right')}
                                                sx={{
                                                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                    },
                                                }}
                                            >
                                                <ArrowForwardIosIcon />
                                            </IconButton>
                                        )}
                                </Box>
                            )}

                        </Box>
                    </Box>
                </Box>
            ) : (
                <>
                    <Box sx={{
                        maxHeight: isMobile ? '380px' : '350px',
                        paddingBottom: isMobile ? `${artists.length * 320}px` : `${artists.length * 100}px`,
                        mt: 2,
                        ml: isMobile ? 5 : 10,
                        mr: isMobile ? 5 : 10
                    }}
                    >
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': {
                                    width: isMobile ? calculateWidth(35) : '55ch',
                                    mt: 3,
                                },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Box sx={{
                                padding: isMobile ? '20px 5px' : '20px 10px',
                            }}>

                                <Box sx={{
                                    mt: 6,
                                    mb: 3
                                }}>
                                    {actionArtistList === 'top5' && (
                                        <>
                                            <Typography
                                                variant={isMobile ? "h4" : "h4"}
                                                fontWeight="bold"
                                                sx={{
                                                    textAlign: isMobile ? 'center' : 'left',
                                                    color: '#2e263de6'
                                                }}
                                            >
                                                Top 5 artistas
                                            </Typography>

                                            <Typography
                                                variant={"subtitle1"}
                                                sx={{
                                                    textAlign: isMobile ? 'center' : 'left',
                                                    color: '#2e263db3'
                                                }}
                                            >
                                                Os artistas mais bem ranqueados
                                            </Typography>
                                        </>
                                    )}

                                    {actionArtistList === 'favorites' && (
                                        <>
                                            <Typography
                                                variant={isMobile ? "h4" : "h4"}
                                                fontWeight="bold"
                                                sx={{
                                                    textAlign: isMobile ? 'center' : 'left',
                                                    color: '#2e263de6'
                                                }}
                                            >
                                                Favoritos
                                            </Typography>

                                            {userRole === 'contractor' && (
                                                <Typography
                                                    variant={"subtitle1"}
                                                    sx={{
                                                        textAlign: isMobile ? 'center' : 'left',
                                                        color: '#2e263db3'
                                                    }}
                                                >
                                                    Os seus artistas favoritos ficam salvos aqui
                                                </Typography>
                                            )}

                                            {/* {userRole === 'artist' && (
                                                <Typography
                                                    variant={"subtitle1"}
                                                    sx={{
                                                        textAlign: isMobile ? 'center' : 'left',
                                                        color: '#2e263db3'
                                                    }}
                                                >
                                                    Seus contratantes favoritos
                                                </Typography>
                                            )} */}
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>


                    {!loading && (
                        <ThereIsNothingHere title={title} subtitle={subtitle} />
                    )}

                </>
            )}
        </>
    );
};

export default CardsList;