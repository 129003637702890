import UserProfileComments from "../comment/UserProfileComments";
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery, CircularProgress } from '@mui/material';
import ThereIsNothingHere from "../utils/ThereIsNothingHere";
import HeroFooter from "../HeroFooter";

const Comments = () => {
    const { isAuthenticated, user } = useAuth0();
    const { id: userID } = useParams();
    const actionUserComments = 'showAllUserComments';
    const [commentsData, setCommentsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
    const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        const fetchComments = async () => {
            if (isAuthenticated && user && userID && apiAuthKey) {
                setIsLoading(true);
                try {
                    const response = await fetch(
                        `${railwayAPIURL}/api/v1/profile/getallusercomments?auth0_sub_id=${userID}`,
                        {
                            method: 'GET',
                            credentials: "include",
                            headers: {
                                'Content-Type': 'application/json',
                                'authKey': apiAuthKey,
                            },
                        }
                    );
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    if (data && data.users && data.users.length === 0) {
                        setCommentsData({ users: [] });
                    } else {
                        setCommentsData(data);
                    }
                } catch (error) {
                    console.error('Error fetching comments:', error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };

        fetchComments();
    }, [isAuthenticated, user, userID, railwayAPIURL, apiAuthKey]);

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 30 }}>
                <CircularProgress sx={{ color: "#4F46E5" }} />
                <Typography sx={{ mt: 2 }} variant="h6" color="textSecondary">
                    Carregando seus comentários ...
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Box sx={{
                padding: isMobile ? '20px 5px' : '20px 10px',
                marginBottom: '40px',
                mx: 8
            }}>
                <Box sx={{
                    mt: 6,
                    mb: 3
                }}>
                    {(commentsData?.users?.length) && (
                        <>
                            <Typography
                                variant="h4"
                                fontWeight="bold"
                                sx={{
                                    textAlign: 'left',
                                    color: '#2e263de6'
                                }}
                            >
                                Meus comentários
                            </Typography>

                            <Typography
                                variant="subtitle1"
                                sx={{
                                    textAlign: 'left',
                                    color: '#2e263db3',
                                    mb: 5
                                }}
                            >
                                As suas opiniões ficam salvas aqui
                            </Typography>
                        </>
                    )}

                    {!(commentsData?.users?.length) && (
                        <ThereIsNothingHere
                            title={'Ainda não tem nada por aqui'}
                            subtitle={'Ainda não tem comentários ainda? Explore nossa seleção e encontre o artista perfeito para sua próxima experiência!'}
                        />
                    )}

                    {commentsData && commentsData.users && commentsData.users.length > 0 && commentsData.users.map((userComment, index) => (
                        <Box
                            key={`comment-${index}`}
                            sx={{
                                mb: 6
                            }}
                        >
                            <React.Fragment key={userComment.nickname}>
                                <Typography variant="h5" gutterBottom
                                    sx={{
                                        textAlign: 'left',
                                    }}
                                >
                                    {userComment.nickname}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom
                                    sx={{
                                        textAlign: 'left',
                                    }}
                                >
                                    {userComment.name}
                                </Typography>
                                <UserProfileComments
                                    actionUserComments={actionUserComments}
                                    artist={userComment}
                                />
                            </React.Fragment>
                        </Box>
                    ))}

                    {!isAuthenticated && (
                        <Typography variant="body1">Faça login para visualizar os comentários</Typography>
                    )}
                </Box>
            </Box>
            <HeroFooter />
        </>
    );
};

export default Comments;