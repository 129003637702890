import { Stack, Card, CardContent, CardMedia, Skeleton, useMediaQuery, Box, Button, TextField, MenuItem, AppBar, Toolbar, Divider, Grid, Typography, Snackbar, Alert, ToggleButton, ToggleButtonGroup, Select, Tooltip, IconButton, Chip, InputLabel, FormControl, OutlinedInput, InputAdornment, ClickAwayListener, Checkbox, ListItemText } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useState, useEffect } from 'react';
import BoxProfile from "./BoxProfile";
import BoxPaperPhoto from "./BoxPaperPhoto";
import { useAuth0 } from '@auth0/auth0-react';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import MicExternalOnOutlinedIcon from '@mui/icons-material/MicExternalOnOutlined';
import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const UserProfile = ({
    userRole,
    userPicture,
    isProfileCompleted,
    currentRoute
}) => {
    const { isAuthenticated, user } = useAuth0();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [activeButton, setActiveButton] = useState(null);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [fullName, setFullName] = useState("");
    const [establishmentName, setEstablishmentName] = useState("");
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");
    const [city, setCity] = useState("");
    const [zone, setZone] = useState("");
    const [address, setAddress] = useState("");
    const [addressNumber, setAddressNumber] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [complement, setComplement] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");

    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const [zones, setZones] = useState([]);

    const fileStoreServiceAPIURl = process.env.REACT_APP_FILE_STORAGE_SERVICE_BASE_URL;
    const fileStoreServiceAPIAuthKey = process.env.REACT_APP_FILE_STORAGE_AUTH_KEY;
    const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
    const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const userRoleProfile = userRole === 'contractor' ? 'Contratante' : userRole === 'artist' ? 'Artista' : userRole;

    const [establishmentImagesBase64, setEstablishmentImagesBase64] = useState([]);
    const [artistImagesBase64, setArtistImagesBase64] = useState([]);

    const [snackbarOpenFieldMissing, setSnackbarOpenFieldMissing] = useState(false);
    const [snackbarOpenUpdateSuccess, setSnackbarOpenUpdateSuccess] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [loadingButtonLoad, setLoadingButtonLoad] = useState(false);

    const [artistProfile, setArtistProfile] = useState(() => ["band"]);
    const [artistNameLabel, setArtistNameLabel] = useState("");
    const [artistImageTitleLabel, setArtistImageTitleLabel] = useState("");
    const [artistName, setArtistName] = useState("");

    const [musicStylesAvailableToSelect, setMusicStylesAvailableToSelect] = useState([]);
    const [selectedMusicStyles, setSelectedMusicStyles] = useState([]);
    const [periodAvailableToSelect, setPeriodAvailableToSelect] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState([]);
    const [membersNumber, setMembersNumber] = useState("");
    const [price, setPrice] = useState('');
    const [artistDescriptionLabel, setArtistDescriptionLabel] = useState("");
    const [artistDescription, setArtistDescription] = useState("");
    const [openTooltip, setOpenTooltip] = useState(false);
    const [openTooltipPrice, setOpenTooltipPrice] = useState(false);

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };

    useEffect(() => {
        const newArtistNameLabel = `Nome ${artistProfile.includes("band") ? "da Banda" :
            artistProfile.includes("musician") ? "do Músico" :
                artistProfile.includes("dj") && "do DJ"
            }`;
        const newArtistDescriptionLabel = `Descrição ${artistProfile.includes("band") ? "da Banda" :
            artistProfile.includes("musician") ? "do Músico" :
                artistProfile.includes("dj") && "do DJ"
            }`;

        const newTitleArtistImageLabel = `Foto ${artistProfile.includes("band") ? "da Banda" :
            artistProfile.includes("musician") ? "do Músico" :
                artistProfile.includes("dj") && "do DJ"
            }`;

        setArtistNameLabel(newArtistNameLabel);
        setArtistDescriptionLabel(newArtistDescriptionLabel);
        setArtistImageTitleLabel(newTitleArtistImageLabel);
    }, [artistProfile, setArtistNameLabel]);

    useEffect(() => {
        setActiveButton(0);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const calculateWidth = (baseChValue) => {
        const chInPixels = 8;
        const baseWidthInPixels = baseChValue * chInPixels;

        const adjustedWidthInPixels = baseWidthInPixels - (screenWidth * 0.1);
        const adjustedWidthInCh = adjustedWidthInPixels / chInPixels;

        return `${adjustedWidthInCh}ch`;
    };


    const getRandomDelay = () => {
        const delays = [1000, 1200, 1400];
        return delays[Math.floor(Math.random() * delays.length)];
    };

    setTimeout(() => {
        setIsPageLoading(false);
    }, getRandomDelay());

    const handleFullName = (event) => {
        setFullName(event.target.value);

    };

    // const handleEstablishmentName = (event) => {
    //     setEstablishmentName(event.target.value);
    // }

    const handleArtistName = (event) => {
        setArtistName(event.target.value);
    }

    const handleMembersNumber = (event) => {
        let value = event.target.value;

        value = value.replace(/[^0-9-]/g, '');

        setMembersNumber(value);
    }

    const handleCountry = (event) => {
        setCountry(event.target.value);
    };

    const handleProvince = (event) => {
        setProvince(event.target.value);
    };

    const handleCity = (event) => {
        setCity(event.target.value);
    };

    const handleZone = (event) => {
        setZone(event.target.value);
    };

    const handleAddress = (event) => {
        setAddress(event.target.value);
    }

    const handleAdressNumber = (event) => {
        let value = event.target.value;

        value = value.replace(/[^0-9-]/g, '');

        setAddressNumber(value);
    }

    const handleZipcodeChange = (event) => {
        let value = event.target.value;


        value = value.replace(/[^0-9-]/g, '');


        if (value.length > 5 && !value.includes('-')) {
            value = `${value.slice(0, 5)}-${value.slice(5, 8)}`;
        }


        if (value.length <= 9) {
            setZipcode(value);
        }
    };

    const handleComplement = (event) => {
        setComplement(event.target.value);
    };

    const handleArtistDescription = (event) => {
        setArtistDescription(event.target.value);
    }

    const handleTooltipPriceClose = () => {
        setOpenTooltipPrice(false);
    };

    const handleTooltipPriceOpen = () => {
        setOpenTooltipPrice(true);
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/dictionary/getdictionary`,
                    {
                        method: 'GET',
                        credentials: "include",
                        headers: {
                            'Content-Type': 'application/json',
                            'authKey': apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch countries');
                }
                const result = await response.json();

                setCountries(result.data[0].countries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, [railwayAPIURL, apiAuthKey]);

    useEffect(() => {
        if (country) {
            const fetchProvinces = async () => {
                try {
                    const response = await fetch(
                        `${railwayAPIURL}/api/v1/dictionary/getdictionary?country=${country}`,
                        {
                            method: 'GET',
                            credentials: "include",
                            headers: {
                                'Content-Type': 'application/json',
                                'authKey': apiAuthKey,
                            },
                        }
                    );
                    if (!response.ok) {
                        throw new Error('Failed to fetch provinces');
                    }
                    const result = await response.json();

                    setProvinces(result.data[0].countries[0].provinces);
                } catch (error) {
                    console.error('Error fetching provinces:', error);
                }
            };

            fetchProvinces();
        }
    }, [country, railwayAPIURL, apiAuthKey]);

    useEffect(() => {
        if (province) {
            const fetchCities = async () => {
                try {
                    const response = await fetch(
                        `${railwayAPIURL}/api/v1/dictionary/getdictionary?country=${country}&province=${province}`,
                        {
                            method: 'GET',
                            credentials: "include",
                            headers: {
                                'Content-Type': 'application/json',
                                'authKey': apiAuthKey,
                            },
                        }
                    );
                    if (!response.ok) {
                        throw new Error('Failed to fetch cities');
                    }
                    const result = await response.json();

                    setCities(result.data[0].countries[0].provinces[0].cities);
                } catch (error) {
                    console.error('Error fetching cities:', error);
                }
            };

            fetchCities();
        }
    }, [province, country, railwayAPIURL, apiAuthKey]);


    useEffect(() => {
        if (city) {
            const selectedCity = cities.find(c => c.city === city);
            if (selectedCity) {
                setZones(selectedCity.zones);
            }
        }
    }, [city, cities]);

    useEffect(() => {
        const fetchMusicStyles = async () => {
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/dictionary/getdictionary?allMusicStyles=true`,
                    {
                        method: 'GET',
                        credentials: "include",
                        headers: {
                            'Content-Type': 'application/json',
                            'authKey': apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch music_styles');
                }
                const result = await response.json();

                setMusicStylesAvailableToSelect(result.data[0].music_styles);
            } catch (error) {
                console.error('Error fetching music_styles:', error);
            }
        };
        if (railwayAPIURL && apiAuthKey) {
            fetchMusicStyles();
        }

    }, [railwayAPIURL, apiAuthKey]);

    useEffect(() => {
        const fetchPeriod = async () => {
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/dictionary/getdictionary?allPeriods=true`,
                    {
                        method: 'GET',
                        credentials: "include",
                        headers: {
                            'Content-Type': 'application/json',
                            'authKey': apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch period');
                }
                const result = await response.json();

                setPeriodAvailableToSelect(result.data[0].period);
            } catch (error) {
                console.error('Error fetching period:', error);
            }
        };
        if (railwayAPIURL && apiAuthKey) {
            fetchPeriod();
        }

    }, [railwayAPIURL, apiAuthKey]);

    const formatPhoneNumber = (value) => {

        const cleaned = value.replace(/\D/g, '');


        const withoutCountryCode = cleaned.startsWith('55') ? cleaned.slice(2) : cleaned;

        let formattedValue = '+55 ';

        if (withoutCountryCode.length > 0) {
            formattedValue += `(${withoutCountryCode.slice(0, 2)}`;
        }
        if (withoutCountryCode.length > 2) {
            formattedValue += `) ${withoutCountryCode.slice(2, 7)}`;
        }
        if (withoutCountryCode.length > 7) {
            formattedValue += `-${withoutCountryCode.slice(7, 11)}`;
        }

        return formattedValue.trim();
    };

    const handleMobilePhoneChange = (event) => {
        const value = event.target.value;
        const formattedValue = formatPhoneNumber(value);
        setMobilePhone(formattedValue);
    };

    // const handleEstablishmentImagesBase64 = (value) => {
    //     setEstablishmentImagesBase64(value);
    // };

    const handleArtistImagesBase64 = (value) => {
        setArtistImagesBase64(value);
    };

    const handleSnackbarCloseFieldMissing = () => {
        setSnackbarOpenFieldMissing(false);
    };

    const handleSnackbarCloseUpdateSuccess = () => {
        setSnackbarOpenUpdateSuccess(false);
    };

    const handleUpdateAuth0User = async (dataName, dataValue) => {
        const headers = new Headers();
        headers.append("authKey", apiAuthKey);
        headers.append("Content-Type", "application/json");
        let data = {};
        switch (dataName) {
            case 'name':
                data = JSON.stringify({
                    "userId": user.sub,
                    "data": {
                        "dataName": dataName,
                        "dataValue": dataValue
                    }
                });

                break;
            case 'isProfileCompleted':
                data = JSON.stringify({
                    "userId": user.sub,
                    "data": {
                        "dataName": dataName,
                        "dataValue": dataValue
                    }
                });

                break;

            default:
                break;
        }

        const requestOptions = {
            method: "PUT",
            credentials: "include",
            headers: headers,
            body: data,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${railwayAPIURL}/api/v1/profile/updateauth0user`, requestOptions);

            if (response.ok) {

            } else {
                console.error('Falha ao atualizar Auth0 user:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao atualizar Auth0 user:', error);
        }
    };

    const base64ToBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    }

    const isHttpsUrl = (imageBase64) => {
        return typeof imageBase64 === 'string' && imageBase64.toLowerCase().startsWith('https://');
    };

    const handleUploadImages = async () => {

        let imagesBase64;
        if (userRole === 'contractor') {
            if (!establishmentImagesBase64 || establishmentImagesBase64.length === 0) return;
        } else {
            if (userRole === 'artist') {
                if (!artistImagesBase64 || artistImagesBase64.length === 0) return;
            }
        }

        if (userRole === 'contractor') {
            imagesBase64 = establishmentImagesBase64;
        } else {
            if (userRole === 'artist') {
                imagesBase64 = artistImagesBase64;
            }
        }

        const uploadedImageUrls = [];

        for (let imageBase64 of imagesBase64) {
            if (!imageBase64) continue;

            if (!isHttpsUrl(imageBase64)) {
                const base64String = imageBase64.split(',')[1];
                const blob = base64ToBlob(base64String, "image/png");

                try {
                    const response = await fetch(
                        `${fileStoreServiceAPIURl}/saveImage`,
                        {
                            method: 'POST',
                            headers: {
                                'authKey': `${fileStoreServiceAPIAuthKey}`,
                            },
                            body: blob,
                        }
                    );

                    if (response.ok) {
                        const data = await response.json();
                        const imageUrl = data.imageURL;

                        uploadedImageUrls.push(imageUrl);
                    } else {
                        console.error('Falha no upload:', response.statusText);
                    }
                } catch (error) {
                    console.error('Erro no upload do arquivo:', error);
                }
            } else {
                uploadedImageUrls.push(imageBase64);
            }


        }

        if (uploadedImageUrls.length > 0) {
            await handleUpdateUser(uploadedImageUrls);
        }
    };

    const handleUpdateUser = async (uploadedImageUrls) => {

        const headers = new Headers();
        headers.append("authKey", apiAuthKey);
        headers.append("Content-Type", "application/json");
        let data;
        if (userRole === 'contractor') {
            data = JSON.stringify({
                "auth0_sub_id": user.sub,
                "name": fullName,
                "establishment_name": establishmentName,
                "country": country,
                "province": province,
                "city": city,
                "zone": zone,
                "address": address,
                "address_number": addressNumber,
                "zipcode": zipcode,
                "complement": complement,
                "establishment_pictures": uploadedImageUrls,
                "mobile_phone": mobilePhone
            });
        } else {
            if (userRole === 'artist') {
                data = JSON.stringify({
                    "auth0_sub_id": user.sub,
                    "name": fullName,
                    "country": country,
                    "province": province,
                    "city": city,
                    "zone": zone,
                    "artist_type": artistProfile,
                    "artist_name": artistName,
                    "music_styles": selectedMusicStyles,
                    "period": selectedPeriod,
                    "members_number": membersNumber,
                    "price": price,
                    "artist_description": artistDescription,
                    "artist_pictures": uploadedImageUrls,
                    "mobile_phone": mobilePhone
                });
            }
        }

        const requestOptions = {
            method: "PUT",
            credentials: "include",
            headers: headers,
            body: data,
            redirect: "follow"
        };


        try {
            const response = await fetch(`${railwayAPIURL}/api/v1/profile/updateuser`, requestOptions);
            if (response.ok) {
                await Promise.all([
                    handleUpdateAuth0User('name', fullName),
                    handleUpdateAuth0User('isProfileCompleted', true)
                ]);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setTimeout(() => {
                setUpdateInProgress(false)

                setSnackbarMessage('Perfil atualizado com sucesso.');
                setSnackbarOpenUpdateSuccess(true);

                setLoadingButtonLoad(false);
            }, 3000);
        }

    };

    const handleSaveChanges = async () => {

        if (userRole === 'contractor') {
            if (!fullName.trim()) {
                setSnackbarMessage('Por favor, preencha o nome completo.');
                setSnackbarOpenFieldMissing(true);
                return;
            }

            if (!establishmentName.trim()) {
                setSnackbarMessage('Por favor, preencha o nome do estabelecimento.');
                setSnackbarOpenFieldMissing(true);
                return;
            }

            if (!country.trim()) {
                setSnackbarMessage('Por favor, selecione o país.');
                setSnackbarOpenFieldMissing(true);
                return;
            }

            if (!province.trim()) {
                setSnackbarMessage('Por favor, selecione o estado.');
                setSnackbarOpenFieldMissing(true);
                return;
            }

            if (!city.trim()) {
                setSnackbarMessage('Por favor, selecione a cidade.');
                setSnackbarOpenFieldMissing(true);
                return;
            }

            if (!zone.trim()) {
                setSnackbarMessage('Por favor, selecione a região.');
                setSnackbarOpenFieldMissing(true);
                return;
            }

            if (!address.trim()) {
                setSnackbarMessage('Por favor, preencha o endereço.');
                setSnackbarOpenFieldMissing(true);
                return;
            }

            if (!addressNumber.trim()) {
                setSnackbarMessage('Por favor, preencha o número.');
                setSnackbarOpenFieldMissing(true);
                return;
            }

            if (!zipcode.trim()) {
                setSnackbarMessage('Por favor, preencha o cep.');
                setSnackbarOpenFieldMissing(true);
                return;
            }

            if (establishmentImagesBase64.length === 0) {
                setSnackbarMessage('Por favor, selecione pelo menos uma foto do estabelecimento.');
                setSnackbarOpenFieldMissing(true);
                return;
            }

            if (!mobilePhone.trim()) {
                setSnackbarMessage('Por favor, preencha o whatsapp.');
                setSnackbarOpenFieldMissing(true);
                return;
            }

        } else {
            if (userRole === 'artist') {
                if (!fullName.trim()) {
                    setSnackbarMessage('Por favor, preencha o nome completo');
                    setSnackbarOpenFieldMissing(true);
                    return;
                }

                if (!artistName.trim()) {
                    setSnackbarMessage(`Por favor, preencha o ${artistNameLabel}`);
                    setSnackbarOpenFieldMissing(true);
                    return;
                }
                if (selectedMusicStyles.length === 0) {
                    setSnackbarMessage("Por favor, selecione pelo menos um estilo musical");
                    setSnackbarOpenFieldMissing(true);
                    return;
                }
                if (selectedPeriod.length === 0) {
                    setSnackbarMessage("Por favor, selecione pelo menos um período");
                    setSnackbarOpenFieldMissing(true);
                    return;
                }
                if (!membersNumber.trim()) {
                    setSnackbarMessage("Por favor, preencha a número de integrantes");
                    setSnackbarOpenFieldMissing(true);
                    return;
                }
                if (!price.trim()) {
                    setSnackbarMessage("Por favor, preencha o preço");
                    setSnackbarOpenFieldMissing(true);
                    return;
                }
                if (!artistDescription.trim()) {
                    setSnackbarMessage(`Por favor, preencha a ${artistDescriptionLabel}`);
                    setSnackbarOpenFieldMissing(true);
                    return;
                } else {
                    if (artistDescription.length < 100) {
                        setSnackbarMessage(`A ${artistDescriptionLabel} deve ter no mínimo de 100 caracteres`);
                        setSnackbarOpenFieldMissing(true);
                        return;
                    }
                }
                if (artistImagesBase64.length === 0) {
                    setSnackbarMessage(`Por favor, selecione pelo menos uma ${artistImageTitleLabel}`);
                    setSnackbarOpenFieldMissing(true);
                    return;
                }
                if (!mobilePhone.trim()) {
                    setSnackbarMessage('Por favor, preencha o whatsapp.');
                    setSnackbarOpenFieldMissing(true);
                    return;
                }

            }
        }
        setUpdateInProgress(true);
        setLoadingButtonLoad(true);

        try {
            handleUploadImages();
        } catch (error) {
            console.error('Erro ao atualizar informações no Auth0:', error);
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            if (isProfileCompleted) {
                try {
                    const response = await fetch(
                        `${railwayAPIURL}/api/v1/profile/getuser?auth0_sub_id=${user.sub}`,
                        {
                            method: 'GET',
                            credentials: "include",
                            headers: {
                                'Content-Type': 'application/json',
                                'authKey': apiAuthKey,
                            },
                        }
                    );
                    if (!response.ok) {
                        throw new Error('Failed to fetch countries');
                    }
                    const result = await response.json();
                    if (userRole === 'contractor') {
                        setFullName(result.user.name);
                        setEstablishmentName(result.user.establishment_name);
                        setCountry(result.user.country);
                        setProvince(result.user.province);
                        setCity(result.user.city);
                        setZone(result.user.zone);
                        setAddress(result.user.address);
                        setAddressNumber(result.user.address_number.toString());
                        setZipcode(result.user.zipcode);
                        setComplement(result.user.complement);
                        setEstablishmentImagesBase64(result.user.establishment_pictures);
                        setMobilePhone(result.user.mobile_phone);
                    } else {
                        if (userRole === 'artist') {
                            setFullName(result.user.name);
                            setArtistProfile(result.user.artist_type);
                            setArtistName(result.user.artist_name);
                            setCountry(result.user.country);
                            setProvince(result.user.province);
                            setCity(result.user.city);
                            setZone(result.user.zone);
                            setSelectedMusicStyles(result.user.music_styles);
                            setSelectedPeriod(result.user.period);
                            setMembersNumber(result.user.members_number.toString());
                            setPrice(result.user.price);
                            setArtistDescription(result.user.artist_description);
                            setArtistImagesBase64(result.user.artist_pictures);
                            setMobilePhone(result.user.mobile_phone);
                        }
                    }





                } catch (error) {
                    console.error('Error fetching countries:', error);
                }
            }
        };

        fetchUser();
    }, [railwayAPIURL, apiAuthKey, user.sub, isProfileCompleted, userRole]);

    const handleArtistProfile = (event, newStyle) => {
        if (newStyle !== null) {
            setArtistProfile(newStyle);
        }
    };

    const handleSelectMusicStyleChange = (event) => {
        const {
            target: { value }
        } = event;
        setSelectedMusicStyles(
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleSelectPeriodChange = (event) => {
        const {
            target: { value }
        } = event;
        setSelectedPeriod(
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handlePriceChange = (event) => {
        let value = event.target.value;

        value = value.replace(/\D/g, '');

        const numericValue = parseInt(value, 10);

        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(numericValue / 100);

        const finalValue = formattedValue.replace(/^R\$\s?/, '').trim();

        setPrice(finalValue);
    };

    const TooltipContent = ({ text }) => (
        <div style={{ whiteSpace: 'normal', maxWidth: '200px' }}>
            {text}
        </div>
    );

    return (
        <>
            {isPageLoading ? (
                <>
                    <Skeleton variant="rectangular" width="100%" height={300} sx={{ borderRadius: 2, mb: 2 }} />
                    <Skeleton variant="rectangular" width="100%" height={150} sx={{ borderRadius: 2 }} />
                </>
            ) : (
                <>
                    <Box
                        sx={{
                            paddingTop: '80px',
                            paddingLeft: '20px',
                            paddingRight: '20px'
                        }}
                    >
                        {/* <Box sx={{ p: 2, borderRadius: '16px', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                            <Button
                                variant={activeButton === 0 ? "contained" : "text"}
                                onClick={() => setActiveButton(0)}
                                sx={{
                                    color: activeButton === 0 ? 'white' : '#614ED9',
                                    borderRadius: '8px',
                                    width: '120px',
                                    mx: 1,
                                    backgroundColor: activeButton === 0 && '#614ED9',
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <PermIdentityOutlinedIcon sx={{ mr: 1 }} />
                                conta
                            </Button>
                            <Button
                                variant={activeButton === 1 ? "contained" : "text"}
                                onClick={() => setActiveButton(1)}
                                sx={{
                                    color: activeButton === 1 ? 'white' : '#614ED9',
                                    borderRadius: '8px',
                                    width: '160px',
                                    mx: 1,
                                    backgroundColor: activeButton === 1 && '#614ED9',
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    alignItems: 'center',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                <AccountBalanceWalletOutlinedIcon sx={{ mr: 1 }} />
                                serviços & plano
                            </Button>

                        </Box> */}

                        {activeButton === 0 && (
                            <>
                                <Card
                                    sx={{
                                        width: '100%',
                                        borderRadius: '16px',
                                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                        overflow: 'hidden',
                                        mb: 2
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        image="/static/images/good_vibes_waves_purple.webp"
                                        alt="Music Profile Patterns"
                                        sx={{ height: 150 }}
                                    />

                                    <CardContent >
                                        <BoxProfile
                                            isAuthenticated={isAuthenticated}
                                            user={user}
                                            userRole={userRole}
                                            userPicture={userPicture}
                                        />
                                    </CardContent>

                                </Card>

                                <Box
                                    sx={{
                                        maxHeight: '300px',
                                        paddingBottom: '84px',
                                        mt: 8,
                                    }}
                                >

                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root':
                                            {

                                                m: 1,
                                                width: isMobile ? calculateWidth(35) : '55ch',
                                                mt: 3,
                                            },
                                            paddingBottom: '100px',
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >


                                        {!isMobile ? (
                                            <>
                                                <Divider sx={{ mt: 4, mb: 4 }} />
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item xs={12}
                                                        sm={6}
                                                        sx={{
                                                            textAlign: 'left',
                                                            ml: 3
                                                        }}>
                                                        <Typography variant="body1" fontWeight="bold">
                                                            Dados de Usuário
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1}>
                                                        <TextField
                                                            disabled
                                                            id="id"
                                                            label="ID"
                                                            value={user.sub}
                                                        />
                                                        <TextField
                                                            disabled
                                                            id="perfil"
                                                            label="Perfil"
                                                            value={userRoleProfile}
                                                        />
                                                        <TextField
                                                            disabled
                                                            id="email"
                                                            label="E-mail"
                                                            value={user.email}
                                                        />
                                                        <TextField
                                                            disabled
                                                            id="usuario"
                                                            label="Usuário"
                                                            value={user.nickname}
                                                        />
                                                        <TextField
                                                            required
                                                            id="nome-completo"
                                                            label="Nome Completo"
                                                            value={fullName}
                                                            onChange={handleFullName}

                                                        />

                                                    </Grid>
                                                </Grid>
                                            </>

                                        ) : (
                                            <>
                                                <Divider sx={{ mt: 4, mb: 4 }} />
                                                <Typography variant="body1" fontWeight="bold">Dados de Usuário</Typography>

                                                <TextField
                                                    disabled
                                                    id="id"
                                                    label="ID"
                                                    value={user.sub}
                                                />
                                                <TextField
                                                    disabled
                                                    id="perfil"
                                                    label="Perfil"
                                                    value={userRoleProfile}
                                                />
                                                <TextField
                                                    disabled
                                                    id="email"
                                                    label="E-mail"
                                                    value={user.email}
                                                />
                                                <TextField
                                                    disabled
                                                    id="usuario"
                                                    label="Usuário"
                                                    value={user.nickname}
                                                />
                                                <TextField
                                                    required
                                                    id="nome-completo"
                                                    label="Nome Completo"
                                                    value={fullName}
                                                    onChange={handleFullName}
                                                />
                                            </>

                                        )}

                                        {userRole === 'artist' && (
                                            <>
                                                {!isMobile ? (
                                                    <>
                                                        {/* <Divider sx={{ mt: 4, mb: 4 }} /> */}
                                                        <Grid container spacing={8}>
                                                            <Grid item xs={14} sm={6}>
                                                                {/* <Typography variant="body1" fontWeight="bold">Dados de Endereço</Typography> */}
                                                            </Grid>

                                                            <Grid item xs={1} sm={1}>
                                                                <TextField
                                                                    select
                                                                    id="pais"
                                                                    label="País"
                                                                    value={country}
                                                                    onChange={handleCountry}
                                                                    helperText="*País suportado unicamente"
                                                                >
                                                                    {countries && countries.map((country) => (
                                                                        <MenuItem key={country.country} value={country.country}>
                                                                            {country.country}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>


                                                                <TextField
                                                                    select
                                                                    id="estado"
                                                                    label="Estado"
                                                                    value={province}
                                                                    onChange={handleProvince}
                                                                    helperText="*Estado suportado unicamente"
                                                                >
                                                                    {provinces && provinces.map((prov) => (
                                                                        <MenuItem key={prov.province} value={prov.province}>
                                                                            {prov.province}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>

                                                                <TextField
                                                                    select
                                                                    id="cidade"
                                                                    label="Cidade"
                                                                    value={city}
                                                                    onChange={handleCity}
                                                                    helperText="*Cidade suportada unicamente"
                                                                >
                                                                    {cities && cities.map((ct) => (
                                                                        <MenuItem key={ct.city} value={ct.city}>
                                                                            {ct.city}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>

                                                                <TextField
                                                                    select
                                                                    id="region"
                                                                    label="Região"
                                                                    value={zone}
                                                                    onChange={handleZone}
                                                                >
                                                                    {zones && zones.map((zn) => (
                                                                        <MenuItem key={zn} value={zn}>
                                                                            {zn}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* <Divider sx={{ mt: 4, mb: 4 }} />
                                                        <Typography variant="body1" fontWeight="bold">Dados de Endereço</Typography> */}
                                                        <TextField
                                                            select
                                                            id="pais"
                                                            label="País"
                                                            value={country}
                                                            onChange={handleCountry}
                                                            helperText="*País suportado unicamente"
                                                        >
                                                            {countries && countries.map((country) => (
                                                                <MenuItem key={country.country} value={country.country}>
                                                                    {country.country}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>


                                                        <TextField
                                                            select
                                                            id="estado"
                                                            label="Estado"
                                                            value={province}
                                                            onChange={handleProvince}
                                                            helperText="*Estado suportado unicamente"
                                                        >
                                                            {provinces && provinces.map((prov) => (
                                                                <MenuItem key={prov.province} value={prov.province}>
                                                                    {prov.province}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                        <TextField
                                                            select
                                                            id="cidade"
                                                            label="Cidade"
                                                            value={city}
                                                            onChange={handleCity}
                                                            helperText="*Cidade suportada unicamente"
                                                        >
                                                            {cities && cities.map((ct) => (
                                                                <MenuItem key={ct.city} value={ct.city}>
                                                                    {ct.city}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                        <TextField
                                                            select
                                                            id="region"
                                                            label="Região"
                                                            value={zone}
                                                            onChange={handleZone}
                                                        >
                                                            {zones && zones.map((zn) => (
                                                                <MenuItem key={zn} value={zn}>
                                                                    {zn}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {!isMobile ? (
                                            <>
                                                {userRole === 'artist' && (
                                                    <>
                                                        <Divider sx={{ mt: 4, mb: 4 }} />
                                                    </>
                                                )}
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item
                                                        xs={14}
                                                        sm={6}
                                                        sx={{
                                                            textAlign: 'left',
                                                            ml: 3
                                                        }}
                                                    >
                                                        {userRole === 'contractor' ? (
                                                            <>
                                                                {/* <Typography variant="body1" fontWeight="bold">Dados do Estabelecimento</Typography> */}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {userRole === 'artist' && (
                                                                    <Typography variant="body1" fontWeight="bold">Dados Artísticos</Typography>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>

                                                    <Grid item xs={1} sm={1}>


                                                        {userRole === 'contractor' ? (
                                                            <>
                                                                {/* <TextField
                                                                    required
                                                                    id="nome-do-estabelecimento"
                                                                    label="Nome do Estabelecimento"
                                                                    value={establishmentName}
                                                                    onChange={handleEstablishmentName}
                                                                /> */}

                                                                <TextField
                                                                    select
                                                                    id="pais"
                                                                    label="País"
                                                                    value={country}
                                                                    onChange={handleCountry}
                                                                    helperText="*País suportado unicamente"
                                                                >
                                                                    {countries && countries.map((country) => (
                                                                        <MenuItem key={country.country} value={country.country}>
                                                                            {country.country}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>


                                                                <TextField
                                                                    select
                                                                    id="estado"
                                                                    label="Estado"
                                                                    value={province}
                                                                    onChange={handleProvince}
                                                                    helperText="*Estado suportado unicamente"
                                                                >
                                                                    {provinces && provinces.map((prov) => (
                                                                        <MenuItem key={prov.province} value={prov.province}>
                                                                            {prov.province}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>

                                                                <TextField
                                                                    select
                                                                    id="cidade"
                                                                    label="Cidade"
                                                                    value={city}
                                                                    onChange={handleCity}
                                                                    helperText="*Cidade suportada unicamente"
                                                                >
                                                                    {cities && cities.map((ct) => (
                                                                        <MenuItem key={ct.city} value={ct.city}>
                                                                            {ct.city}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>

                                                                <TextField
                                                                    select
                                                                    id="region"
                                                                    label="Região"
                                                                    value={zone}
                                                                    onChange={handleZone}
                                                                >
                                                                    {zones && zones.map((zn) => (
                                                                        <MenuItem key={zn} value={zn}>
                                                                            {zn}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>

                                                                <TextField
                                                                    required
                                                                    id="endereco"
                                                                    label="Endereço"
                                                                    value={address}
                                                                    onChange={handleAddress}
                                                                />

                                                                <TextField
                                                                    required
                                                                    id="numero"
                                                                    label="Nº"
                                                                    type="number"
                                                                    variant="outlined"
                                                                    value={addressNumber}
                                                                    onChange={handleAdressNumber}
                                                                    slotProps={{
                                                                        inputLabel: {
                                                                            shrink: true,
                                                                        },
                                                                    }}
                                                                />

                                                                <TextField
                                                                    required
                                                                    id="cep"
                                                                    label="Cep"
                                                                    variant="outlined"
                                                                    value={zipcode}
                                                                    onChange={handleZipcodeChange}
                                                                    inputProps={{
                                                                        maxLength: 9,
                                                                    }}
                                                                />

                                                                <TextField
                                                                    id="complemento"
                                                                    label="Complemento"
                                                                    value={complement}
                                                                    onChange={handleComplement}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {userRole === 'artist' && (
                                                                    <>
                                                                        <Box sx={{
                                                                            m: 1,
                                                                            width: '55ch',
                                                                            display: 'flex',
                                                                            justifyContent: 'flex-start',
                                                                            mb: 2
                                                                        }}>
                                                                            <Typography
                                                                                noWrap
                                                                                variant="body1"
                                                                                sx={{
                                                                                    mt: 2,
                                                                                    mr: 2,
                                                                                    mb: 1,
                                                                                    display: 'inline-flex',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                Cadastrar como:
                                                                                <Tooltip title="Selecione o perfil artístico que melhor representa você entre as opções abaixo.">
                                                                                    <IconButton size="small" sx={{ ml: 1 }}>
                                                                                        <InfoOutlinedIcon fontSize="small" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Typography>
                                                                            <ToggleButtonGroup
                                                                                value={artistProfile}
                                                                                exclusive
                                                                                onChange={handleArtistProfile}
                                                                                aria-label="styles"
                                                                                sx={{
                                                                                    height: '56px',
                                                                                }}
                                                                            >
                                                                                <ToggleButton value="band" aria-label="band">
                                                                                    <Groups2OutlinedIcon />
                                                                                    <Typography
                                                                                        sx={{
                                                                                            ml: 2,
                                                                                            textTransform: 'capitalize',
                                                                                        }}
                                                                                    >
                                                                                        Banda
                                                                                    </Typography>
                                                                                </ToggleButton>

                                                                                <ToggleButton value="musician" aria-label="musician">
                                                                                    <MicExternalOnOutlinedIcon />
                                                                                    <Typography
                                                                                        sx={{
                                                                                            ml: 2,
                                                                                            textTransform: 'capitalize',
                                                                                        }}
                                                                                    >
                                                                                        Músico
                                                                                    </Typography>
                                                                                </ToggleButton>

                                                                                <ToggleButton value="dj" aria-label="dj">
                                                                                    <AlbumOutlinedIcon />
                                                                                    <Typography
                                                                                        sx={{
                                                                                            ml: 2,
                                                                                            textTransform: 'capitalize',
                                                                                        }}
                                                                                    >
                                                                                        DJ
                                                                                    </Typography>
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </Box>

                                                                        <TextField
                                                                            required
                                                                            id="nome-artista"
                                                                            label={artistNameLabel}
                                                                            value={artistName}
                                                                            onChange={handleArtistName}
                                                                        />

                                                                        <FormControl
                                                                            required
                                                                            sx={{
                                                                                m: 1,
                                                                                width: isMobile ? calculateWidth(35) : '55ch',
                                                                                mt: 3
                                                                            }}
                                                                        >
                                                                            <InputLabel id="music-styles-label">Estilos Musicais</InputLabel>
                                                                            <Select
                                                                                labelId="music-styles-label"
                                                                                id="select-music-styles"
                                                                                multiple
                                                                                value={selectedMusicStyles}
                                                                                onChange={handleSelectMusicStyleChange}
                                                                                input={<OutlinedInput label="Estilos Musicais" />}
                                                                                renderValue={(selected) => (
                                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                        {selected.map((style) => (
                                                                                            <Chip key={style} label={style} />
                                                                                        ))}
                                                                                    </Box>
                                                                                )}
                                                                                MenuProps={{
                                                                                    PaperProps: {
                                                                                        style: {
                                                                                            maxHeight: 48 * 4.5 + 8,
                                                                                            width: 250,
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {musicStylesAvailableToSelect.map((style) => (
                                                                                    <MenuItem key={style} value={style}>
                                                                                        <Checkbox checked={selectedMusicStyles.includes(style)} />
                                                                                        <ListItemText primary={style} />
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>

                                                                        <FormControl
                                                                            required
                                                                            sx={{
                                                                                m: 1,
                                                                                width: isMobile ? calculateWidth(35) : '55ch',
                                                                                mt: 3
                                                                            }}
                                                                        >
                                                                            <InputLabel id="period-label">Períodos de Trabalho</InputLabel>
                                                                            <Select
                                                                                labelId="period-label"
                                                                                id="select-period"
                                                                                multiple
                                                                                value={selectedPeriod}
                                                                                onChange={handleSelectPeriodChange}
                                                                                input={<OutlinedInput label="Períodos de Trabalho" />}
                                                                                renderValue={(selected) => (
                                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                        {selected.map((period) => (
                                                                                            <Chip key={period} label={period} />
                                                                                        ))}
                                                                                    </Box>
                                                                                )}
                                                                                MenuProps={{
                                                                                    PaperProps: {
                                                                                        style: {
                                                                                            maxHeight: 48 * 4.5 + 8,
                                                                                            width: 250,
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {periodAvailableToSelect.map((period) => (
                                                                                    <MenuItem key={period} value={period}>
                                                                                        <Checkbox checked={selectedPeriod.includes(period)} />
                                                                                        <ListItemText primary={period} />
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>

                                                                        <TextField
                                                                            required
                                                                            id="members_number"
                                                                            label="Número de Integrantes"
                                                                            type="number"
                                                                            variant="outlined"
                                                                            value={membersNumber}
                                                                            onChange={handleMembersNumber}
                                                                            inputProps={{ min: 1 }}
                                                                            slotProps={{
                                                                                inputLabel: {
                                                                                    shrink: true,
                                                                                },
                                                                            }}
                                                                        />

                                                                        <FormControl
                                                                            required
                                                                            fullWidth
                                                                            sx={{
                                                                                m: 1,
                                                                                width: isMobile ? calculateWidth(35) : '55ch',
                                                                            }}
                                                                        >

                                                                            <InputLabel htmlFor="outlined-adornment-amount">Valor por Show</InputLabel>
                                                                            <OutlinedInput
                                                                                id="price"
                                                                                value={price}
                                                                                onChange={handlePriceChange}
                                                                                startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                                                label="Valor por Show"
                                                                                endAdornment={
                                                                                    <ClickAwayListener onClickAway={handleTooltipPriceClose}>
                                                                                        <Tooltip
                                                                                            PopperProps={{ disablePortal: true }}
                                                                                            onClose={handleTooltipPriceClose}
                                                                                            open={openTooltipPrice}
                                                                                            disableFocusListener
                                                                                            disableHoverListener
                                                                                            disableTouchListener
                                                                                            title="Valor cobrado por show: este será o valor exibido para os contratantes, representando quanto você cobra para se apresentar."
                                                                                        >
                                                                                            <IconButton
                                                                                                size="small"
                                                                                                onClick={handleTooltipPriceOpen}
                                                                                                sx={{ ml: 1 }}
                                                                                            >
                                                                                                <InfoOutlinedIcon fontSize="small" sx={{ color: '#614ED9' }} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </ClickAwayListener>
                                                                                }
                                                                            />

                                                                        </FormControl>

                                                                        <TextField
                                                                            required
                                                                            id="artist-description"
                                                                            label={artistDescriptionLabel}
                                                                            multiline
                                                                            rows={4}
                                                                            value={artistDescription}
                                                                            onChange={handleArtistDescription}
                                                                        />
                                                                    </>
                                                                )}

                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>


                                            </>
                                        ) : (
                                            <>
                                                {userRole === 'artist' && (
                                                    <>
                                                        <Divider sx={{ mt: 4, mb: 4 }} />
                                                    </>
                                                )}
                                                {userRole === 'contractor' ? (
                                                    <>
                                                        {/* <Typography variant="body1" fontWeight="bold">Dados do Estabelecimento</Typography> */}
                                                        {/* <TextField
                                                            required
                                                            id="nome-do-estabelecimento"
                                                            label="Nome do Estabelecimento"
                                                            value={establishmentName}
                                                            onChange={handleEstablishmentName}
                                                        /> */}

                                                        <TextField
                                                            select
                                                            id="pais"
                                                            label="País"
                                                            value={country}
                                                            onChange={handleCountry}
                                                            helperText="*País suportado unicamente"
                                                        >
                                                            {countries && countries.map((country) => (
                                                                <MenuItem key={country.country} value={country.country}>
                                                                    {country.country}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>


                                                        <TextField
                                                            select
                                                            id="estado"
                                                            label="Estado"
                                                            value={province}
                                                            onChange={handleProvince}
                                                            helperText="*Estado suportado unicamente"
                                                        >
                                                            {provinces && provinces.map((prov) => (
                                                                <MenuItem key={prov.province} value={prov.province}>
                                                                    {prov.province}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                        <TextField
                                                            select
                                                            id="cidade"
                                                            label="Cidade"
                                                            value={city}
                                                            onChange={handleCity}
                                                            helperText="*Cidade suportada unicamente"
                                                        >
                                                            {cities && cities.map((ct) => (
                                                                <MenuItem key={ct.city} value={ct.city}>
                                                                    {ct.city}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                        <TextField
                                                            select
                                                            id="region"
                                                            label="Região"
                                                            value={zone}
                                                            onChange={handleZone}
                                                        >
                                                            {zones && zones.map((zn) => (
                                                                <MenuItem key={zn} value={zn}>
                                                                    {zn}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>


                                                        <TextField
                                                            required
                                                            id="endereco"
                                                            label="Endereço"
                                                            value={address}
                                                            onChange={handleAddress}
                                                        />

                                                        <TextField
                                                            required
                                                            id="numero"
                                                            label="Nº"
                                                            type="tel"
                                                            variant="outlined"
                                                            value={addressNumber}
                                                            onChange={handleAdressNumber}
                                                            slotProps={{
                                                                inputLabel: {
                                                                    shrink: true,
                                                                },
                                                            }}
                                                        />

                                                        <TextField
                                                            required
                                                            id="cep"
                                                            label="Cep"
                                                            variant="outlined"
                                                            value={zipcode}
                                                            onChange={handleZipcodeChange}
                                                            inputProps={{
                                                                maxLength: 9,
                                                            }}
                                                        />

                                                        <TextField
                                                            id="complemento"
                                                            label="Complemento"
                                                            value={complement}
                                                            onChange={handleComplement}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        {userRole === 'artist' && (
                                                            <>
                                                                <Typography variant="body1" fontWeight="bold">Dados Artísticos</Typography>


                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-start',
                                                                    ml: 3,
                                                                    mr: 3,
                                                                    mb: 1
                                                                }}>
                                                                    <Stack
                                                                        direction={isMobile ? 'column' : 'row'}
                                                                        sx={{ width: '100%' }}
                                                                    >
                                                                        <Typography
                                                                            noWrap
                                                                            variant="body1"
                                                                            sx={{
                                                                                mt: 2,
                                                                                mr: 2,
                                                                                mb: 1,
                                                                                alignItems: 'center',
                                                                                flexShrink: 0,
                                                                            }}
                                                                        >
                                                                            Cadastrar como:
                                                                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                                                                <Tooltip
                                                                                    PopperProps={{
                                                                                        disablePortal: true,
                                                                                    }}
                                                                                    onClose={handleTooltipClose}
                                                                                    open={openTooltip}
                                                                                    disableFocusListener
                                                                                    disableHoverListener
                                                                                    disableTouchListener
                                                                                    title={
                                                                                        <TooltipContent text="Selecione o perfil artístico que melhor representa você entre as opções abaixo." />
                                                                                    }
                                                                                >
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        sx={{ ml: 1 }}
                                                                                        onClick={handleTooltipOpen}
                                                                                    >
                                                                                        <InfoOutlinedIcon fontSize="small" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </ClickAwayListener>
                                                                        </Typography>

                                                                        <ToggleButtonGroup
                                                                            value={artistProfile}
                                                                            exclusive
                                                                            onChange={handleArtistProfile}
                                                                            aria-label="styles"
                                                                            sx={{
                                                                                height: '56px',
                                                                                flexGrow: 1,
                                                                            }}
                                                                        >
                                                                            <ToggleButton value="band" aria-label="band" sx={{ flexGrow: 1 }}>
                                                                                <Groups2OutlinedIcon />
                                                                                <Typography
                                                                                    sx={{
                                                                                        ml: 1,
                                                                                        textTransform: 'capitalize',
                                                                                    }}
                                                                                >
                                                                                    Banda
                                                                                </Typography>
                                                                            </ToggleButton>

                                                                            <ToggleButton value="musician" aria-label="musician" sx={{ flexGrow: 1 }}>
                                                                                <MicExternalOnOutlinedIcon />
                                                                                <Typography
                                                                                    sx={{
                                                                                        ml: 1,
                                                                                        textTransform: 'capitalize',
                                                                                    }}
                                                                                >
                                                                                    Músico
                                                                                </Typography>
                                                                            </ToggleButton>

                                                                            <ToggleButton value="dj" aria-label="dj" sx={{ flexGrow: 1 }}>
                                                                                <AlbumOutlinedIcon />
                                                                                <Typography
                                                                                    sx={{
                                                                                        ml: 1,
                                                                                        textTransform: 'capitalize',
                                                                                    }}
                                                                                >
                                                                                    DJ
                                                                                </Typography>
                                                                            </ToggleButton>
                                                                        </ToggleButtonGroup>
                                                                    </Stack>
                                                                </Box>

                                                                <TextField
                                                                    required
                                                                    id="nome-artista"
                                                                    label={artistNameLabel}
                                                                    value={artistName}
                                                                    onChange={handleArtistName}
                                                                    fullWidth
                                                                />

                                                                <FormControl
                                                                    required
                                                                    sx={{
                                                                        m: 1,
                                                                        width: isMobile ? calculateWidth(35) : '55ch',
                                                                        mt: 3
                                                                    }}
                                                                >
                                                                    <InputLabel id="music-styles-label">Estilos Musicais</InputLabel>
                                                                    <Select
                                                                        labelId="music-styles-label"
                                                                        id="select-music-styles"
                                                                        multiple
                                                                        value={selectedMusicStyles}
                                                                        onChange={handleSelectMusicStyleChange}
                                                                        input={<OutlinedInput label="Estilos Musicais" />}
                                                                        renderValue={(selected) => (
                                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                {selected.map((style) => (
                                                                                    <Chip key={style} label={style} />
                                                                                ))}
                                                                            </Box>
                                                                        )}
                                                                        MenuProps={{
                                                                            PaperProps: {
                                                                                style: {
                                                                                    maxHeight: 48 * 4.5 + 8,
                                                                                    width: 250,
                                                                                },
                                                                            },
                                                                        }}
                                                                    >
                                                                        {musicStylesAvailableToSelect.map((style) => (
                                                                            <MenuItem key={style} value={style}>
                                                                                <Checkbox checked={selectedMusicStyles.includes(style)} />
                                                                                <ListItemText primary={style} />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>

                                                                <FormControl
                                                                    required
                                                                    sx={{
                                                                        m: 1,
                                                                        width: isMobile ? calculateWidth(35) : '55ch',
                                                                        mt: 3
                                                                    }}
                                                                >
                                                                    <InputLabel id="period-label">Períodos de Trabalho</InputLabel>
                                                                    <Select
                                                                        labelId="period-label"
                                                                        id="select-period"
                                                                        multiple
                                                                        value={selectedPeriod}
                                                                        onChange={handleSelectPeriodChange}
                                                                        input={<OutlinedInput label="Períodos de Trabalho" />}
                                                                        renderValue={(selected) => (
                                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                {selected.map((period) => (
                                                                                    <Chip key={period} label={period} />
                                                                                ))}
                                                                            </Box>
                                                                        )}
                                                                        MenuProps={{
                                                                            PaperProps: {
                                                                                style: {
                                                                                    maxHeight: 48 * 4.5 + 8,
                                                                                    width: 250,
                                                                                },
                                                                            },
                                                                        }}
                                                                    >
                                                                        {periodAvailableToSelect.map((period) => (
                                                                            <MenuItem key={period} value={period}>
                                                                                <Checkbox checked={selectedPeriod.includes(period)} />
                                                                                <ListItemText primary={period} />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>

                                                                <TextField
                                                                    required
                                                                    id="members_number"
                                                                    label="Número de Integrantes"
                                                                    type="text"
                                                                    variant="outlined"
                                                                    value={membersNumber}
                                                                    onChange={handleMembersNumber}
                                                                    slotProps={{
                                                                        inputLabel: {
                                                                            shrink: true,
                                                                        },
                                                                        input: {
                                                                            inputMode: "numeric",
                                                                            pattern: "[0-9]*"
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            inputMode: "numeric",
                                                                            pattern: "[0-9]*"
                                                                        }
                                                                    }}
                                                                />

                                                                <FormControl
                                                                    required
                                                                    fullWidth
                                                                    sx={{
                                                                        m: 1,
                                                                        width: isMobile ? calculateWidth(35) : '55ch',
                                                                    }}
                                                                >
                                                                    <InputLabel htmlFor="outlined-adornment-amount">Valor por Show</InputLabel>
                                                                    <OutlinedInput
                                                                        id="price"
                                                                        value={price}
                                                                        onChange={handlePriceChange}
                                                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                                        label="Valor por Show"
                                                                        endAdornment={
                                                                            <ClickAwayListener onClickAway={handleTooltipPriceClose}>
                                                                                <Tooltip
                                                                                    PopperProps={{ disablePortal: true }}
                                                                                    onClose={handleTooltipPriceClose}
                                                                                    open={openTooltipPrice}
                                                                                    disableFocusListener
                                                                                    disableHoverListener
                                                                                    disableTouchListener
                                                                                    title="Valor cobrado por show: este será o valor exibido para os contratantes, representando quanto você cobra para se apresentar."
                                                                                >
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={handleTooltipPriceOpen}
                                                                                        sx={{ ml: 1 }}
                                                                                    >
                                                                                        <InfoOutlinedIcon fontSize="small" sx={{ color: '#614ED9' }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </ClickAwayListener>
                                                                        }
                                                                    />
                                                                </FormControl>

                                                                <TextField
                                                                    required
                                                                    id="artist-description"
                                                                    label={artistDescriptionLabel}
                                                                    multiline
                                                                    rows={4}
                                                                    value={artistDescription}
                                                                    onChange={handleArtistDescription}
                                                                />


                                                            </>
                                                        )}
                                                    </>
                                                )}


                                            </>
                                        )}

                                        {!isMobile ? (
                                            <>
                                                {userRole === 'artist' && (
                                                    <>
                                                        <Divider sx={{ mt: 4, mb: 4 }} />
                                                    </>
                                                )}
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        sx={{
                                                            textAlign: 'left',
                                                            ml: 3
                                                        }}
                                                    >
                                                        {userRole === 'contractor' ? (
                                                            <>
                                                                {/* <Divider sx={{ mt: 4, mb: 4 }} /> */}
                                                                {/* <Typography variant="body1" fontWeight="bold">Fotos do Estabelecimento</Typography> */}
                                                            </>
                                                        ) : (
                                                            <>

                                                                {userRole === 'artist' && (
                                                                    <>
                                                                        <Typography variant="body1" fontWeight="bold">Fotos de Trabalhos ou Shows Realizados</Typography>
                                                                        <Typography
                                                                            variant="body2"
                                                                            fontWeight="bold"
                                                                            color="textSecondary"
                                                                            sx={{ fontSize: '0.775rem' }}
                                                                        >
                                                                            (Esta é a sua vitrine! Capriche nas fotos para impressionar. 😊)
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={3} sm={5}>
                                                        {userRole === 'contractor' ? (
                                                            <>
                                                                {/* <Divider sx={{ mt: 4, mb: 4 }} /> */}
                                                                {/* <BoxPaperPhoto
                                                                    imagesBase64={establishmentImagesBase64}
                                                                    imagesBase64Prop={handleEstablishmentImagesBase64}
                                                                /> */}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {userRole === 'artist' && (
                                                                    <BoxPaperPhoto
                                                                        imagesBase64={artistImagesBase64}
                                                                        imagesBase64Prop={handleArtistImagesBase64}
                                                                    />
                                                                )}
                                                            </>
                                                        )}

                                                    </Grid>
                                                </Grid>

                                            </>
                                        ) : (
                                            <>
                                                {userRole === 'artist' && (
                                                    <>
                                                        <Divider sx={{ mt: 4, mb: 4 }} />
                                                    </>
                                                )}

                                                {userRole === 'contractor' ? (
                                                    <>
                                                        {/* <Divider sx={{ mt: 4, mb: 4 }} /> */}
                                                        {/* <Typography variant="body1" fontWeight="bold">Fotos do Estabelecimento</Typography>
                                                        <BoxPaperPhoto
                                                            imagesBase64={establishmentImagesBase64}
                                                            imagesBase64Prop={handleEstablishmentImagesBase64}
                                                        /> */}
                                                    </>
                                                ) : (
                                                    <>

                                                        {userRole === 'artist' && (
                                                            <>
                                                                <Typography variant="body1" fontWeight="bold">Fotos de Trabalhos ou Shows Realizados</Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    fontWeight="bold"
                                                                    color="textSecondary"
                                                                    sx={{ fontSize: '0.675rem' }}
                                                                >
                                                                    (Esta é a sua vitrine! Capriche nas fotos para impressionar. 😊)
                                                                </Typography>
                                                                <BoxPaperPhoto
                                                                    imagesBase64={artistImagesBase64}
                                                                    imagesBase64Prop={handleArtistImagesBase64}
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                )}

                                            </>
                                        )}


                                        {!isMobile ? (
                                            <>
                                                <Divider sx={{ mt: 4, mb: 4 }} />
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item
                                                        xs={14}
                                                        sm={6}
                                                        sx={{
                                                            textAlign: 'left',
                                                            ml: 3
                                                        }}
                                                    >
                                                        <Typography variant="body1" fontWeight="bold">Dados de Contato</Typography>
                                                        {
                                                            userRole === "contractor" ? (
                                                                <Typography
                                                                    variant="body2"
                                                                    fontWeight="bold"
                                                                    color="textSecondary"
                                                                    sx={{ fontSize: '0.775rem' }}
                                                                >
                                                                    (Este será o número que os artistas usarão para entrar em contato com você)
                                                                </Typography>
                                                            ) : userRole === "artist" ? (
                                                                <Typography
                                                                    variant="body2"
                                                                    fontWeight="bold"
                                                                    color="textSecondary"
                                                                    sx={{ fontSize: '0.775rem' }}
                                                                >
                                                                    (Este será o número que os clientes usarão para contratar seus serviços)
                                                                </Typography>
                                                            ) : null
                                                        }
                                                    </Grid>
                                                    <Grid item xs={1} sm={1}>
                                                        <TextField
                                                            required
                                                            id="whatsapp"
                                                            label="Whatsapp"
                                                            value={mobilePhone}
                                                            onChange={handleMobilePhoneChange}
                                                            placeholder="+55 (XX) XXXXX-XXXX"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Divider sx={{ mt: 4, mb: 4 }} />
                                                <Typography variant="body1" fontWeight="bold">Dados de Contato</Typography>
                                                {
                                                    userRole === "contractor" ? (
                                                        <Typography
                                                            variant="body2"
                                                            fontWeight="bold"
                                                            color="textSecondary"
                                                            sx={{ fontSize: '0.775rem' }}
                                                        >
                                                            (Este será o número que os artistas usarão para entrar em contato com você)
                                                        </Typography>
                                                    ) : userRole === "artist" ? (
                                                        <Typography
                                                            variant="body2"
                                                            fontWeight="bold"
                                                            color="textSecondary"
                                                            sx={{ fontSize: '0.775rem' }}
                                                        >
                                                            (Este será o número que os clientes usarão para contratar seus serviços)
                                                        </Typography>
                                                    ) : null
                                                }
                                                <TextField
                                                    required
                                                    id="whatsapp"
                                                    label="Whatsapp"
                                                    value={mobilePhone}
                                                    onChange={handleMobilePhoneChange}
                                                    placeholder="+55 (XX) XXXXX-XXXX"
                                                />
                                            </>
                                        )}


                                    </Box>
                                    <AppBar
                                        position="fixed"
                                        sx={{
                                            top: 'auto',
                                            bottom: 0,
                                            backgroundColor: '#F5F5F5'
                                        }}>
                                        <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Box sx={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: isMobile ? 'center' : 'flex-end',
                                                mt: 2,
                                                mb: 2
                                            }}>
                                                {updateInProgress ? (
                                                    <>

                                                        <LoadingButton
                                                            color="secondary"
                                                            loading={loadingButtonLoad}
                                                            loadingPosition="start"
                                                            startIcon={<SaveIcon />}
                                                            variant="contained"
                                                            sx={{
                                                                width: '200px',
                                                                textTransform: 'capitalize',
                                                                backgroundColor: '#614ED9'
                                                            }}
                                                        >
                                                            salvar mudanças
                                                        </LoadingButton>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                textTransform: 'capitalize',
                                                                backgroundColor: '#614ED9'
                                                            }}
                                                            onClick={handleSaveChanges}
                                                        >
                                                            salvar mudanças
                                                        </Button>
                                                    </>
                                                )}
                                            </Box>

                                            <Snackbar
                                                open={snackbarOpenFieldMissing}
                                                autoHideDuration={3000}
                                                onClose={handleSnackbarCloseFieldMissing}
                                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                                <Alert onClose={handleSnackbarCloseFieldMissing} severity="error" sx={{ width: '100%' }}>
                                                    {snackbarMessage}
                                                </Alert>
                                            </Snackbar>

                                            <Snackbar
                                                open={snackbarOpenUpdateSuccess}
                                                autoHideDuration={3000}
                                                onClose={handleSnackbarCloseUpdateSuccess}
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                            >
                                                <Alert onClose={handleSnackbarCloseUpdateSuccess} severity="success" sx={{ width: '100%' }}>
                                                    {snackbarMessage}
                                                </Alert>
                                            </Snackbar>

                                        </Toolbar>
                                    </AppBar>
                                </Box>
                            </>
                        )}



                    </Box >


                </>
            )
            }
        </>
    );
};

export default UserProfile;
